import React, { useState, useContext, useEffect } from 'react';
import { Container, Form, Button, Row, Col, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from '../../context/context';
import api from '../../config/axios';
import { API_ENDPOINTS } from '../../utils/constants';

export default function DashboardPerson() {
  const navigate = useNavigate();
  const { state } = useContext(Store);
  const { userInfo } = state;
  const [loading, setLoading] = useState(true);


  // Updated questions focusing on behavioral and team dynamics
  const questions = [
    {
      id: 'communicationStyle',
      label: 'How would you describe your communication style within a team? Do you tend to listen to others and reflect on their opinions, or are you more likely to take the initiative by speaking up and suggesting solutions? Why?',
      type: 'textarea',
      placeholder: 'Describe your typical communication approach and give specific examples...'
    },
    {
      id: 'projectStructure',
      label: 'How important is it for you to have a clear project structure from the start? Do you typically stick to the initial plans, or do you allow them to change as new information comes in? What motivates you to choose one approach over the other?',
      type: 'textarea',
      placeholder: 'Explain your approach to project planning and adaptation...'
    },
    {
      id: 'obstacleHandling',
      label: 'How do you react when you encounter unexpected obstacles or mistakes at work? Do you tend to look for solutions independently, rely on the team, or take on a different role? What helps (or hinders) you in staying focused during such moments?',
      type: 'textarea',
      placeholder: 'Share your approach to problem-solving and maintaining focus...'
    },
    {
      id: 'feedbackStyle',
      label: 'How do you strive to create a supportive environment when giving constructive feedback? Do you focus more on emotional support, objectivity, or directness? Why do you believe this method is most effective?',
      type: 'textarea',
      placeholder: 'Describe your feedback style and its effectiveness...'
    },
    {
      id: 'conflictResolution',
      label: 'How do you respond when there is a conflict between different perspectives or approaches within the team? Do you primarily seek compromise, clarity, or do you analyze the situation more deeply to understand the root causes? In your opinion, how does this help the team?',
      type: 'textarea',
      placeholder: 'Explain your approach to handling team conflicts...'
    },
    {
      id: 'adaptability',
      label: "What do you usually do when you notice that the project you're working on is taking a completely new direction? Do you focus more on structural changes to the project, the team's emotional well-being, or the long-term goal? What helps you adapt to these changes?",
      type: 'textarea',
      placeholder: 'Describe how you handle and adapt to significant project changes...'
    }
  ];

  // Initialize form state with empty answers and labels for each question
  const [formData, setFormData] = useState({
    employeeProfile: questions.map(q => ({
      questionType: q.id,
      questionLabel: q.label,  // Include the label in the data
      answer: ''
    }))
  });

  // Fetch existing profile data
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const { data } = await api.get('/users/profile');
        
        if (data.user?.employeeProfile?.length) {
          // Map existing answers to questions
          const updatedProfile = questions.map(q => {
            const existingAnswer = data.user.employeeProfile.find(
              p => p.questionType === q.id
            );
            
            return {
              questionType: q.id,
              questionLabel: q.label,
              answer: existingAnswer?.answer || ''
            };
          });

          setFormData({ employeeProfile: updatedProfile });
        }
      } catch (error) {
        console.error('Error fetching profile:', error);
        toast.error(error.response?.data?.message || 'Error loading profile data');
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);


  const handleChange = (questionId, value) => {
    setFormData(prev => ({
      ...prev,
      employeeProfile: prev.employeeProfile.map(item => 
        item.questionType === questionId 
          ? { ...item, answer: value }
          : item
      )
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post(API_ENDPOINTS.UPDATE_PROFILE, formData);
      
      toast.success('Profile updated successfully!');
      navigate('/my-dashboard');
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error(error.response?.data?.message || 'Error saving profile data');
    }
  };

  if (loading) {
    return (
      <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '80vh' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  return (
    <div>
      <Container className="mt-4">
        <h2>Personal Profile Assessment</h2>
        <p className="text-muted mb-4">
          Please provide detailed responses to help us understand your work style and team dynamics.
        </p>
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Col>
              {questions.map((question) => (
                <Form.Group className="mb-4" key={question.id}>
                  <Form.Label className="fw-bold">{question.label}</Form.Label>
                  <Form.Control
                    as={question.type}
                    rows={4}
                    placeholder={question.placeholder}
                    value={formData.employeeProfile.find(
                      item => item.questionType === question.id
                    )?.answer || ''}
                    onChange={(e) => handleChange(question.id, e.target.value)}
                    required
                  />
                </Form.Group>
              ))}
            </Col>
          </Row>

          <div className="d-flex justify-content-end mt-3">
            <Button variant="success" type="submit">
              Update Profile
            </Button>
          </div>
        </Form>
      </Container>
    </div>
  );
}