// Context/Context.js
import { createContext, useReducer } from 'react';

export const Store = createContext();

const initialState = {
  userInfo: localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null,
  companyInfo: localStorage.getItem('companyInfo')
    ? JSON.parse(localStorage.getItem('companyInfo'))
    : null,
  loading: false,
  error: null,
};

function reducer(state, action) {
  switch (action.type) {
    // User Registration cases
    case 'USER_REGISTER_REQUEST':
      return { ...state, loading: true };
    case 'USER_REGISTER_SUCCESS':
      return {
        ...state,
        loading: false,
        userInfo: action.payload,
        error: null,
      };
    case 'USER_REGISTER_FAIL':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // Company Registration cases
    case 'COMPANY_REGISTER_REQUEST':
      return { ...state, loading: true };
    case 'COMPANY_REGISTER_SUCCESS':
      return {
        ...state,
        loading: false,
        companyInfo: action.payload,
        error: null,
      };
    case 'COMPANY_REGISTER_FAIL':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // User Login cases
    case 'USER_LOGIN_REQUEST':
      return { ...state, loading: true };
    case 'USER_LOGIN_SUCCESS':
      return {
        ...state,
        loading: false,
        userInfo: action.payload,
        error: null,
      };
    case 'USER_LOGIN_FAIL':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // Company Login cases
    case 'COMPANY_LOGIN_REQUEST':
      return { ...state, loading: true };
    case 'COMPANY_LOGIN_SUCCESS':
      return {
        ...state,
        loading: false,
        companyInfo: action.payload,
        error: null,
      };
    case 'COMPANY_LOGIN_FAIL':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // Company Profile Update cases
    case 'COMPANY_PROFILE_UPDATE_REQUEST':
      return { ...state, loading: true };
    case 'COMPANY_PROFILE_UPDATE_SUCCESS':
      const updatedCompanyInfo = {
        ...state.companyInfo,
        ...action.payload,
      };
      localStorage.setItem('companyInfo', JSON.stringify(updatedCompanyInfo));
      return {
        ...state,
        loading: false,
        companyInfo: updatedCompanyInfo,
        error: null,
      };
    case 'COMPANY_PROFILE_UPDATE_FAIL':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // Common cases for logout and error clearing
    case 'USER_SIGNOUT':
      localStorage.removeItem('userInfo');
      return {
        ...state,
        userInfo: null,
        loading: false,
        error: null,
      };
    case 'COMPANY_SIGNOUT':
      localStorage.removeItem('companyInfo');
      return {
        ...state,
        companyInfo: null,
        loading: false,
        error: null,
      };

    case 'CLEAR_ERROR':
      return { ...state, error: null };

    case 'CV_UPDATE_REQUEST':
      return { ...state, loading: true };

    case 'CV_UPDATE_SUCCESS':
      return {
        ...state,
        loading: false,
        userInfo: {
          ...state.userInfo,
          cv: action.payload,
        },
      };

    case 'CV_UPDATE_FAIL':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

export function StoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return <Store.Provider value={value}>{props.children}</Store.Provider>;
}
