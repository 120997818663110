// Components/ApplicationForm.js
import React, { useState, useContext } from 'react';
import { Modal, Form, Button, Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Store } from '../context/context';
import api from '../config/axios';

const ApplicationForm = ({ show, onHide, jobId, jobTitle }) => {
  const [coverLetter, setCoverLetter] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { state } = useContext(Store);
  const { userInfo } = state;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!userInfo) {
      toast.error('Please log in to apply');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await api.post('/applications/submit', {
        jobId,
        coverLetter,
      });

      console.log('Application submission response:', response.data);
      toast.success('Application submitted successfully!');
      onHide();
    } catch (error) {
      console.error('Application submission error:', error);
      setError(error.response?.data?.message || 'Error submitting application');
      toast.error(
        error.response?.data?.message || 'Error submitting application'
      );
    } finally {
      setLoading(false);
    }
  };

  if (!userInfo) {
    return (
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Login Required</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="warning">
            Please log in to apply for this position.
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Apply for {jobTitle}</Modal.Title>
      </Modal.Header>

      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          {error && <Alert variant="danger">{error}</Alert>}

          <Form.Group className="mb-3">
            <Form.Label>Cover Letter</Form.Label>
            <Form.Control
              as="textarea"
              rows={6}
              value={coverLetter}
              onChange={(e) => setCoverLetter(e.target.value)}
              placeholder="Write a cover letter explaining why you're a good fit for this position..."
              required
            />
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Cancel
          </Button>
          <Button variant="primary" type="submit" disabled={loading}>
            {loading ? 'Submitting...' : 'Submit Application'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ApplicationForm;
