// Pages/CompanyDashboard/CandidateSearch.js
import React, { useState, useEffect, useCallback } from 'react';
import { Container, Form, Button, Card, Row, Col, Spinner } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroll-component';
import api from '../../config/axios';
import CVCard from '../../components/cvCard';
import CandidateModal from '../../components/candidateModal';
import { getError } from '../../utils/utils';

const ITEMS_PER_PAGE = 10;

export default function CandidateSearch() {
  const [filters, setFilters] = useState({
    skills: '',
    experience: '',
    location: '',
    education: ''
  });
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const fetchCandidates = useCallback(async (pageNum = 1, newSearch = false) => {
    try {
      setLoading(true);
      
      // Verify company auth before making request
      const companyInfo = localStorage.getItem('companyInfo');
      console.log('Company info available:', !!companyInfo);
      
      if (!companyInfo) {
        toast.error('Company authentication required');
        return;
      }
  
      // Only include non-empty filters
      const nonEmptyFilters = Object.entries(filters).reduce((acc, [key, value]) => {
        if (value) acc[key] = value;
        return acc;
      }, {});
  
      const params = new URLSearchParams({
        ...nonEmptyFilters,
        page: pageNum,
        limit: ITEMS_PER_PAGE
      });
  
      console.log('Making search request:', {
        url: `/users/search?${params}`,
        hasCompanyInfo: !!companyInfo,
        filters: nonEmptyFilters
      });
  
      const { data } = await api.get(`/users/search?${params}`);
      console.log('Search response:', data); // Add this log
  
      // Handle new search vs pagination
      if (newSearch) {
        setCandidates(data.users || []);
      } else {
        setCandidates(prev => [...prev, ...(data.users || [])]);
      }
      
      // Update pagination state
      setHasMore((data.users || []).length === ITEMS_PER_PAGE);
      setPage(pageNum);
      
    } catch (error) {
      console.error('Search error details:', {
        status: error.response?.status,
        message: error.response?.data?.message,
        hasCompanyInfo: !!localStorage.getItem('companyInfo'),
        errorObject: error
      });
      toast.error(getError(error) || 'Error fetching candidates');
      setCandidates([]);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  }, [filters]);
  
  // Update useEffect to handle filters changes
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchCandidates(1, true);
    }, 500);
  
    return () => clearTimeout(delayDebounceFn);
  }, [filters, fetchCandidates]);

  const handleSearch = (e) => {
    e.preventDefault();
    fetchCandidates(1, true);
  };

  const loadMore = () => {
    if (!loading && hasMore) {
      fetchCandidates(page + 1);
    }
  };

  const handleViewProfile = (candidate) => {
    setSelectedCandidate(candidate);
    setShowModal(true);
  };

  return (
    <Container className="py-4">
      <Helmet>
        <title>Search Candidates</title>
      </Helmet>

      <Row>
        <Col md={4}>
          <Card className="mb-4 sticky-top" style={{ top: '1rem' }}>
            <Card.Body>
              <Card.Title>Search Filters</Card.Title>
              <Form onSubmit={handleSearch}>
                <Form.Group className="mb-3">
                  <Form.Label>Skills</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="e.g., React, Python"
                    value={filters.skills}
                    onChange={(e) => setFilters(prev => ({...prev, skills: e.target.value}))}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Location</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="City or Region"
                    value={filters.location}
                    onChange={(e) => setFilters(prev => ({...prev, location: e.target.value}))}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Education</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Field of Study"
                    value={filters.education}
                    onChange={(e) => setFilters(prev => ({...prev, education: e.target.value}))}
                  />
                </Form.Group>

                <Button 
                  type="submit" 
                  variant="primary" 
                  className="w-100"
                  disabled={loading}
                >
                  {loading ? 'Searching...' : 'Search Candidates'}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>

        <Col md={8}>
          <Card>
            <Card.Body>
              <Card.Title>Matched Candidates</Card.Title>
              
              {loading && candidates.length === 0 ? (
                <div className="text-center py-4">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : (
                <InfiniteScroll
                  dataLength={candidates.length}
                  next={loadMore}
                  hasMore={hasMore}
                  loader={
                    <div className="text-center py-3">
                      <Spinner animation="border" variant="primary" size="sm" />
                    </div>
                  }
                >
                  {candidates.map(candidate => (
                    <CVCard 
                      key={candidate._id} 
                      candidate={candidate} 
                      onView={() => handleViewProfile(candidate)}
                    />
                  ))}
                  
                  {!loading && candidates.length === 0 && (
                    <div className="text-center py-4">
                      <p className="text-muted">No candidates found matching your criteria</p>
                    </div>
                  )}
                </InfiniteScroll>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <CandidateModal 
        show={showModal}
        onHide={() => setShowModal(false)}
        candidate={selectedCandidate}
      />
    </Container>
  );
}