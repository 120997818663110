// NavBarMain.js

import React from 'react';
import NavBar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';

export default function NavBarMain({ userInfo, signoutHandler }) {
  return (
    <NavBar bg="dark" variant="dark">
      <Container>
        <LinkContainer to="/main-page">
          <NavBar.Brand>Home Page</NavBar.Brand>
        </LinkContainer>
        <LinkContainer to="/about">
          <NavBar.Brand className="nav-link">Apie platforma</NavBar.Brand>
        </LinkContainer>
        <LinkContainer to="/ai">
          <NavBar.Brand className="nav-link">Kaip veikia musu AI</NavBar.Brand>
        </LinkContainer>
        <LinkContainer to="/success">
          <NavBar.Brand className="nav-link">Sekmes istorijos</NavBar.Brand>
        </LinkContainer>

        {/* Register Dropdown Menu */}
        <Nav className="me-auto">
          <NavDropdown title="Register" id="register-nav-dropdown">
            <LinkContainer to="/register-employee">
              <NavDropdown.Item>Register Personal</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/register-company">
              <NavDropdown.Item>Register Company</NavDropdown.Item>
            </LinkContainer>
          </NavDropdown>
        </Nav>

        {/* Login Dropdown Menu */}
        <Nav className="me-auto">
          {userInfo ? (
            <NavDropdown title={userInfo.name} id="user-nav-dropdown">
              <LinkContainer to="/profile">
                <NavDropdown.Item>User Profile</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/profile">
                <NavDropdown.Item>Profile Info</NavDropdown.Item>
              </LinkContainer>
              <NavDropdown.Divider />
              <Link
                className="dropdown-item"
                to="#signout"
                onClick={signoutHandler}
              >
                Logout
              </Link>
            </NavDropdown>
          ) : (
            <NavDropdown title="Login" id="login-nav-dropdown">
              <LinkContainer to="/login-employee">
                <NavDropdown.Item>Login Personal</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/login-company">
                <NavDropdown.Item>Login Company</NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>
          )}
        </Nav>
      </Container>
    </NavBar>
  );
}
