// Pages/CompanyDashboard/CompanyAnalytics.js
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Badge, Alert, Spinner } from 'react-bootstrap';
import { LineChart, BarChart, PieChart, Pie, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Line, Bar, Cell } from 'recharts';
import { Users, Briefcase, ChartBar } from 'lucide-react';
import api from '../../config/axios';

const CompanyAnalytics = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState({
    jobs: [],
    applications: [],
    evaluations: []
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const [jobsRes, applicationsRes] = await Promise.all([
        api.get('/jobs'),
        api.get('/applications/company'),
      ]);

      setData({
        jobs: jobsRes.data || [],
        applications: applicationsRes.data || [],
      });

    } catch (err) {
      console.error('Error fetching analytics data:', err);
      setError('Failed to load analytics data');
    } finally {
      setLoading(false);
    }
  };

  const calculateStats = () => {
    const activeJobs = data.jobs.filter(job => job.status === 'active').length;
    const totalJobs = data.jobs.length;
    const totalApplications = data.applications.length;
    
    const applicationStatuses = data.applications.reduce((acc, app) => {
      acc[app.status] = (acc[app.status] || 0) + 1;
      return acc;
    }, {});

    return {
      activeJobs,
      totalJobs,
      totalApplications,
      applicationStatuses
    };
  };

  if (loading) {
    return (
      <Container className="py-5 text-center">
        <Spinner animation="border" variant="primary" />
        <p className="mt-2">Loading analytics...</p>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="py-5">
        <Alert variant="danger">{error}</Alert>
      </Container>
    );
  }

  const stats = calculateStats();
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

  // Prepare application status data for pie chart
  const applicationStatusData = Object.entries(stats.applicationStatuses).map(([status, count]) => ({
    name: status.charAt(0).toUpperCase() + status.slice(1),
    value: count
  }));

  return (
    <Container className="py-4">
      <h1 className="mb-4">Company Analytics</h1>

      {/* Stats Overview Cards */}
      <Row className="mb-4 g-3">
        <Col md={4}>
          <Card className="h-100">
            <Card.Body className="d-flex align-items-center">
              <Briefcase className="text-primary me-3" size={24} />
              <div>
                <div className="text-muted small">Active Jobs</div>
                <h3 className="mb-0">{stats.activeJobs}</h3>
                <small className="text-muted">of {stats.totalJobs} total jobs</small>
              </div>
            </Card.Body>
          </Card>
        </Col>
        
        <Col md={4}>
          <Card className="h-100">
            <Card.Body className="d-flex align-items-center">
              <Users className="text-success me-3" size={24} />
              <div>
                <div className="text-muted small">Total Applications</div>
                <h3 className="mb-0">{stats.totalApplications}</h3>
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col md={4}>
          <Card className="h-100">
            <Card.Body className="d-flex align-items-center">
              <ChartBar className="text-info me-3" size={24} />
              <div>
                <div className="text-muted small">Application Rate</div>
                <h3 className="mb-0">
                  {stats.totalJobs ? (stats.totalApplications / stats.totalJobs).toFixed(1) : 0}
                </h3>
                <small className="text-muted">applications per job</small>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mb-4">
        {/* Job Status Distribution */}
        <Col md={6}>
          <Card className="h-100">
            <Card.Body>
              <h5 className="mb-4">Job Status Distribution</h5>
              <div className="d-flex justify-content-center">
                <PieChart width={300} height={300}>
                  <Pie
                    data={[
                      { name: 'Active', value: stats.activeJobs },
                      { name: 'Other', value: stats.totalJobs - stats.activeJobs }
                    ]}
                    cx={150}
                    cy={150}
                    labelLine={false}
                    outerRadius={100}
                    fill="#8884d8"
                    dataKey="value"
                    label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                  >
                    {COLORS.map((color, index) => (
                      <Cell key={`cell-${index}`} fill={color} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </div>
            </Card.Body>
          </Card>
        </Col>

        {/* Application Status Distribution */}
        <Col md={6}>
          <Card className="h-100">
            <Card.Body>
              <h5 className="mb-4">Application Status Distribution</h5>
              <div className="d-flex justify-content-center">
                <PieChart width={300} height={300}>
                  <Pie
                    data={applicationStatusData}
                    cx={150}
                    cy={150}
                    labelLine={false}
                    outerRadius={100}
                    fill="#8884d8"
                    dataKey="value"
                    label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                  >
                    {applicationStatusData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Recent Activity */}
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <h5 className="mb-4">Recent Applications</h5>
              {data.applications.length > 0 ? (
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>Job Title</th>
                        <th>Status</th>
                        <th>Applied Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.applications.slice(0, 5).map((application) => (
                        <tr key={application._id}>
                          <td>{application.jobId?.title || 'N/A'}</td>
                          <td>
                            <Badge bg={
                              application.status === 'accepted' ? 'success' :
                              application.status === 'rejected' ? 'danger' :
                              application.status === 'pending' ? 'warning' : 'info'
                            }>
                              {application.status}
                            </Badge>
                          </td>
                          <td>{new Date(application.createdAt).toLocaleDateString()}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <Alert variant="info">No applications yet</Alert>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default CompanyAnalytics;