// src/Pages/Registration/RegistrationPageCompany.js
import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Form, Button, Card } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { Store } from '../../context/context';
import { toast } from 'react-toastify';
import { getError } from '../../utils/utils';
import api from '../../config/axios';
import { API_ENDPOINTS } from '../../utils/constants';

export default function RegistrationPageCompany() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    companyCode: '', // New field for company code
  });

  const { dispatch: ctxDispatch } = useContext(Store);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    
    if (formData.password !== formData.confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }
  
    try {
      const { data } = await api.post(API_ENDPOINTS.COMPANY_REGISTER, {
        name: formData.name,
        email: formData.email,
        password: formData.password,
        companyCode: formData.companyCode, 
      });
  
      ctxDispatch({ type: 'USER_REGISTER', payload: data });
      localStorage.setItem('userInfo', JSON.stringify(data));
      navigate('/dashboard-company');
    } catch (error) {
      console.error('Registration error details:', error.response?.data);
      toast.error(getError(error));
    }
  };

  return (
    <Container className="small-container">
      <Helmet>
        <title>Company Registration</title>
      </Helmet>
      <Card className="p-4 my-3">
        <h1 className="mb-4 text-center">Company Registration</h1>
        <Form onSubmit={submitHandler}>
          <Form.Group className="mb-3" controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="password">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="confirmPassword">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              required
            />
          </Form.Group>

          {/* New input for Company Code */}
          <Form.Group className="mb-3" controlId="companyCode">
            <Form.Label>Company Code</Form.Label>
            <Form.Control
              type="text"
              name="companyCode"
              value={formData.companyCode}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Button type="submit" className="w-100">
            Register
          </Button>
        </Form>
      </Card>
    </Container>
  );
}
