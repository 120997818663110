import React, { useState } from 'react';
import { Form, Button, Card, Row, Col } from 'react-bootstrap';
import EditableCard from './components/editableCard';

const initialProjectState = {
  title: '',
  description: '',
  technologies: '',
  url: '',
  startDate: '',
  endDate: '',
};

const ProjectsForm = ({ data, onUpdate }) => {
  const [newProject, setNewProject] = useState(initialProjectState);

  const ensureValidFormData = (project) => ({
    title: project.title || '',
    description: project.description || '',
    technologies: Array.isArray(project.technologies)
      ? project.technologies.join(', ')
      : project.technologies || '',
    url: project.url || '',
    startDate: project.startDate || '',
    endDate: project.endDate || '',
  });

  const handleAdd = () => {
    if (newProject.title && newProject.description) {
      const formattedProject = {
        ...newProject,
        technologies: newProject.technologies
          .split(',')
          .map((tech) => tech.trim())
          .filter(Boolean),
      };
      onUpdate('projects', [...(data.projects || []), formattedProject]);
      setNewProject(initialProjectState);
    }
  };

  const handleEdit = (index, updatedProj) => {
    const updatedProjects = [...data.projects];
    updatedProjects[index] = {
      ...updatedProj,
      technologies:
        typeof updatedProj.technologies === 'string'
          ? updatedProj.technologies
              .split(',')
              .map((tech) => tech.trim())
              .filter(Boolean)
          : updatedProj.technologies,
    };
    onUpdate('projects', updatedProjects);
  };

  const handleDelete = (index) => {
    const updatedProjects = data.projects.filter((_, i) => i !== index);
    onUpdate('projects', updatedProjects);
  };

  const renderProjectForm = (project, onChange, onCancel, onSubmit) => {
    const formProject = ensureValidFormData(project);

    return (
      <div>
        <Form.Group className="mb-3">
          <Form.Label>Project Title</Form.Label>
          <Form.Control
            type="text"
            value={formProject.title}
            onChange={(e) =>
              onChange({ ...formProject, title: e.target.value })
            }
            required
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={formProject.description}
            onChange={(e) =>
              onChange({ ...formProject, description: e.target.value })
            }
            required
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Technologies (comma-separated)</Form.Label>
          <Form.Control
            type="text"
            value={formProject.technologies}
            onChange={(e) =>
              onChange({ ...formProject, technologies: e.target.value })
            }
            placeholder="React, Node.js, MongoDB"
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Project URL</Form.Label>
          <Form.Control
            type="url"
            value={formProject.url}
            onChange={(e) => onChange({ ...formProject, url: e.target.value })}
            placeholder="https://yourproject.com"
          />
        </Form.Group>

        <Row>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type="date"
                value={formProject.startDate}
                onChange={(e) =>
                  onChange({ ...formProject, startDate: e.target.value })
                }
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>End Date</Form.Label>
              <Form.Control
                type="date"
                value={formProject.endDate}
                onChange={(e) =>
                  onChange({ ...formProject, endDate: e.target.value })
                }
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <div className="d-flex justify-content-end gap-2">
          <Button variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onSubmit}>
            Save
          </Button>
        </div>
      </div>
    );
  };

  const renderProjectDisplay = (project) => (
    <>
      <h5 className="mb-1">{project.title}</h5>
      {project.startDate && project.endDate && (
        <p className="mb-2 text-muted">
          {new Date(project.startDate).toLocaleDateString()} -
          {new Date(project.endDate).toLocaleDateString()}
        </p>
      )}
      <p className="mb-2">{project.description}</p>
      {project.technologies?.length > 0 && (
        <p className="mb-2">
          <strong>Technologies:</strong>{' '}
          {Array.isArray(project.technologies)
            ? project.technologies.join(', ')
            : project.technologies}
        </p>
      )}
      {project.url && (
        <a
          href={project.url}
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-sm btn-outline-primary"
        >
          View Project
        </a>
      )}
    </>
  );

  return (
    <div>
      {data.projects?.map((project, index) => (
        <EditableCard
          key={index}
          data={project}
          onEdit={(updatedProj) => handleEdit(index, updatedProj)}
          onDelete={() => handleDelete(index)}
          renderDisplay={renderProjectDisplay}
          renderEditForm={renderProjectForm}
        />
      ))}

      <Card className="mb-3">
        <Card.Body>
          <Card.Title>Add New Project</Card.Title>
          {renderProjectForm(
            newProject,
            setNewProject,
            () => setNewProject(initialProjectState),
            handleAdd
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default ProjectsForm;
