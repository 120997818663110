// pages/personalDashboard.js
import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import ProfileReminder from '../../components/profileReminder';

export default function PersonalDashboard() {
  return (
    <Container className="mt-3">
      <ProfileReminder />
      
      <h1>My Dashboard</h1>
      <Row>
        <Col md={6}>
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Recent Activity</Card.Title>
              <Card.Text>
                You updated your profile 2 days ago. New job matches available!
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Upcoming Interviews</Card.Title>
              <Card.Text>No interviews scheduled at this time.</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}