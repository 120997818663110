// src/Components/ApplicationDetailsModal.js
import React, { useState } from 'react';
import { Modal, Button, Badge, Form, Row, Col } from 'react-bootstrap';

const ApplicationDetailsModal = ({ 
  show, 
  onHide, 
  application, 
  onUpdateStatus 
}) => {
  const [newStatus, setNewStatus] = useState('');
  const [note, setNote] = useState('');

  const handleStatusUpdate = () => {
    onUpdateStatus(application._id, newStatus, note);
    setNote('');
  };

  if (!application) return null;

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Application Details</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row className="mb-4">
          <Col md={6}>
            <h5>Applicant Information</h5>
            <p><strong>Name:</strong> {application.userId.name}</p>
            <p><strong>Email:</strong> {application.userId.email}</p>
            <p><strong>Applied for:</strong> {application.jobId.title}</p>
            <p>
              <strong>Status:</strong>{' '}
              <Badge bg={
                application.status === 'accepted' ? 'success' :
                application.status === 'rejected' ? 'danger' :
                application.status === 'shortlisted' ? 'warning' :
                'secondary'
              }>
                {application.status}
              </Badge>
            </p>
            <p><strong>Applied on:</strong> {new Date(application.createdAt).toLocaleDateString()}</p>
          </Col>
          <Col md={6}>
            <h5>CV Highlights</h5>
            {application.cv.skills && (
              <div className="mb-2">
                <strong>Skills:</strong><br />
                {application.cv.skills.map((skill, index) => (
                  <Badge key={index} bg="info" className="me-1 mb-1">
                    {skill.name} ({skill.level})
                  </Badge>
                ))}
              </div>
            )}
            {application.cv.experience && application.cv.experience[0] && (
              <div className="mb-2">
                <strong>Latest Experience:</strong><br />
                {application.cv.experience[0].position} at {application.cv.experience[0].company}
              </div>
            )}
          </Col>
        </Row>

        <div className="mb-4">
          <h5>Cover Letter</h5>
          <div className="p-3 bg-light rounded">
            {application.coverLetter}
          </div>
        </div>

        <div className="mb-4">
          <h5>Update Status</h5>
          <Row>
            <Col md={6}>
              <Form.Select
                value={newStatus}
                onChange={(e) => setNewStatus(e.target.value)}
                className="mb-2"
              >
                <option value="">Select new status</option>
                <option value="pending">Pending</option>
                <option value="reviewed">Reviewed</option>
                <option value="shortlisted">Shortlisted</option>
                <option value="rejected">Rejected</option>
                <option value="accepted">Accepted</option>
              </Form.Select>
            </Col>
            <Col md={6}>
              <Button 
                variant="primary"
                disabled={!newStatus}
                onClick={handleStatusUpdate}
              >
                Update Status
              </Button>
            </Col>
          </Row>
        </div>

        {application.notes && application.notes.length > 0 && (
          <div>
            <h5>Notes History</h5>
            {application.notes.map((note, index) => (
              <div key={index} className="p-2 mb-2 bg-light rounded">
                <small className="text-muted">
                  {new Date(note.createdAt).toLocaleString()}
                </small>
                <p className="mb-0">{note.content}</p>
              </div>
            ))}
          </div>
        )}

        <Form.Group>
          <Form.Label>Add Note</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={note}
            onChange={(e) => setNote(e.target.value)}
            placeholder="Add a note about this application..."
          />
        </Form.Group>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ApplicationDetailsModal;