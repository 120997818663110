// src/Components/CV/Forms/ReviewForm.js
import React from 'react';
import { Card, Badge } from 'react-bootstrap';

export default function ReviewForm({ data }) {
  const formatDate = (dateString) => {
    if (!dateString) return '';
    return new Date(dateString).toLocaleDateString();
  };

  const getBadgeVariant = (level, isSkill = true) => {
    if (isSkill) {
      switch (level) {
        case 'Beginner': return 'info';
        case 'Intermediate': return 'primary';
        case 'Advanced': return 'success';
        case 'Expert': return 'danger';
        default: return 'secondary';
      }
    } else {
      switch (level) {
        case 'Basic': return 'info';
        case 'Intermediate': return 'primary';
        case 'Fluent': return 'success';
        case 'Native': return 'danger';
        default: return 'secondary';
      }
    }
  };

  return (
    <div className="review-cv">
      {/* Personal Information */}
      <Card className="mb-4">
        <Card.Header>
          <h5 className="mb-0">Personal Information</h5>
        </Card.Header>
        <Card.Body>
          <div className="row">
            <div className="col-md-6">
              <p><strong>Full Name:</strong> {data.personalInfo?.firstName} {data.personalInfo?.lastName}</p>
              <p><strong>Phone:</strong> {data.personalInfo?.phoneNumber}</p>
              <p><strong>Address:</strong> {data.personalInfo?.address}</p>
            </div>
            <div className="col-md-6">
              <p><strong>Date of Birth:</strong> {formatDate(data.personalInfo?.dateOfBirth)}</p>
              {data.personalInfo?.linkedIn && (
                <p><strong>LinkedIn:</strong> <a href={data.personalInfo.linkedIn} target="_blank" rel="noopener noreferrer">Profile</a></p>
              )}
              {data.personalInfo?.website && (
                <p><strong>Website:</strong> <a href={data.personalInfo.website} target="_blank" rel="noopener noreferrer">Personal Website</a></p>
              )}
            </div>
          </div>
        </Card.Body>
      </Card>

      {/* Work Experience */}
      <Card className="mb-4">
        <Card.Header>
          <h5 className="mb-0">Work Experience</h5>
        </Card.Header>
        <Card.Body>
          {data.experience?.map((exp, index) => (
            <div key={index} className={index !== 0 ? 'mt-4 pt-4 border-top' : ''}>
              <h6>{exp.position} at {exp.company}</h6>
              <p className="text-muted mb-2">
                {exp.location} • {formatDate(exp.startDate)} - {exp.current ? 'Present' : formatDate(exp.endDate)}
              </p>
              <p className="mb-0">{exp.description}</p>
            </div>
          ))}
          {(!data.experience || data.experience.length === 0) && (
            <p className="text-muted">No work experience added</p>
          )}
        </Card.Body>
      </Card>

      {/* Education */}
      <Card className="mb-4">
        <Card.Header>
          <h5 className="mb-0">Education</h5>
        </Card.Header>
        <Card.Body>
          {data.education?.map((edu, index) => (
            <div key={index} className={index !== 0 ? 'mt-4 pt-4 border-top' : ''}>
              <h6>{edu.degree} in {edu.fieldOfStudy}</h6>
              <p className="text-muted mb-2">
                {edu.institution} • {formatDate(edu.startDate)} - {edu.current ? 'Present' : formatDate(edu.endDate)}
              </p>
              {edu.description && <p className="mb-0">{edu.description}</p>}
            </div>
          ))}
          {(!data.education || data.education.length === 0) && (
            <p className="text-muted">No education history added</p>
          )}
        </Card.Body>
      </Card>

      {/* Skills & Languages */}
      <Card className="mb-4">
        <Card.Header>
          <h5 className="mb-0">Skills & Languages</h5>
        </Card.Header>
        <Card.Body>
          <h6>Technical Skills</h6>
          <div className="mb-4">
            {data.skills?.map((skill, index) => (
              <Badge
                key={index}
                bg={getBadgeVariant(skill.level, true)}
                className="me-2 mb-2"
              >
                {skill.name} - {skill.level}
              </Badge>
            ))}
            {(!data.skills || data.skills.length === 0) && (
              <p className="text-muted">No skills added</p>
            )}
          </div>

          <h6>Languages</h6>
          <div>
            {data.languages?.map((language, index) => (
              <Badge
                key={index}
                bg={getBadgeVariant(language.level, false)}
                className="me-2 mb-2"
              >
                {language.name} - {language.level}
              </Badge>
            ))}
            {(!data.languages || data.languages.length === 0) && (
              <p className="text-muted">No languages added</p>
            )}
          </div>
        </Card.Body>
      </Card>

      {/* Projects */}
      <Card className="mb-4">
        <Card.Header>
          <h5 className="mb-0">Projects</h5>
        </Card.Header>
        <Card.Body>
          {data.projects?.map((project, index) => (
            <div key={index} className={index !== 0 ? 'mt-4 pt-4 border-top' : ''}>
              <h6>{project.title}</h6>
              <p className="text-muted mb-2">
                {formatDate(project.startDate)} - {formatDate(project.endDate)}
              </p>
              <p>{project.description}</p>
              <p>
                <strong>Technologies:</strong>{' '}
                {Array.isArray(project.technologies) 
                  ? project.technologies.join(', ') 
                  : project.technologies}
              </p>
              {project.url && (
                <a
                  href={project.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-sm btn-outline-primary"
                >
                  View Project
                </a>
              )}
            </div>
          ))}
          {(!data.projects || data.projects.length === 0) && (
            <p className="text-muted">No projects added</p>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}