import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Form, Button, Spinner, Alert } from 'react-bootstrap';
import api from '../../config/axios';
import { toast } from 'react-toastify';
import ProfileReminder from '../../components/profileReminder';

const JobListings = () => {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedJob, setExpandedJob] = useState(null);
  const [filter, setFilter] = useState('all');

  useEffect(() => {
    fetchJobs();
  }, []);

  const fetchJobs = async () => {
    try {
      const { data } = await api.get('/jobs/public');
      setJobs(data);
    } catch (err) {
      toast.error('Error fetching jobs');
    } finally {
      setLoading(false);
    }
  };

  const filteredJobs = jobs.filter(job => {
    const matchesSearch = job.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      job.description?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      job.company?.toLowerCase().includes(searchTerm.toLowerCase());
    
    const matchesFilter = filter === 'all' || job.type?.toLowerCase() === filter;
    
    return matchesSearch && matchesFilter;
  });

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '80vh' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (jobs.length === 0) {
    return (
      <Container className="py-5">
        <Alert variant="info">
          <Alert.Heading>No Jobs Found</Alert.Heading>
          <p>There are currently no job postings available. Please check back later.</p>
        </Alert>
      </Container>
    );
  }

  return (
    <Container className="py-4">
      <ProfileReminder />
      <Row className="mb-4">
        <Col>
          <h1 className="mb-4">Available Positions</h1>
          
          <Row className="g-3">
            <Col md={8}>
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Search jobs..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Select
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              >
                <option value="all">All Types</option>
                <option value="fulltime">Full Time</option>
                <option value="parttime">Part Time</option>
                <option value="contract">Contract</option>
              </Form.Select>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col>
        {filteredJobs.map((job) => (
  <Card 
    key={job._id} 
    className="mb-3"
    onClick={() => window.open(`/jobs/${job._id}`, '_blank')}
    style={{ cursor: 'pointer' }}
  >
    <Card.Header className="bg-white">
      <div className="d-flex justify-content-between align-items-start">
        <div>
          <h5 className="mb-1 text-primary">{job.title}</h5>
          <div className="text-muted small">
            {job.company && (
              <span className="me-3">
                <i className="bi bi-building me-1"></i>
                {job.company}
              </span>
            )}
            {job.location && (
              <span className="me-3">
                <i className="bi bi-geo-alt me-1"></i>
                {job.location}
              </span>
            )}
            <span>
              <i className="bi bi-calendar me-1"></i>
              {formatDate(job.createdAt)}
            </span>
          </div>
        </div>
      </div>
    </Card.Header>
  </Card>
))}
        </Col>
      </Row>
    </Container>
  );
};

export default JobListings;