// Pages/CompanyDashboard/JobPostings.js
import React, { useState, useEffect } from 'react';
import { Container, Button, Card, Table, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../config/axios';
import JobPostingForm from './jobPostingsForm';
import './jobPostings.css';

export default function JobPostings() {
  const [jobs, setJobs] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [editingJob, setEditingJob] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchJobs();
  }, []);

  const fetchJobs = async () => {
    try {
      const { data } = await api.get('/jobs'); // Token is attached by Axios
      setJobs(data);
    } catch (err) {
      toast.error('Error fetching jobs');
    } finally {
      setLoading(false);
    }
  };

  const handleCreateJob = async (jobData) => {
    try {
      await api.post('/jobs', jobData); // Token is attached by Axios
      toast.success('Job created successfully');
      fetchJobs();
      setShowForm(false);
    } catch (err) {
      toast.error('Error creating job');
    }
  };

  const handleUpdateJob = async (jobData) => {
    try {
      await api.put(`/jobs/${editingJob._id}`, jobData); // Token is attached by Axios
      toast.success('Job updated successfully');
      fetchJobs();
      setShowForm(false);
      setEditingJob(null);
    } catch (err) {
      toast.error('Error updating job');
    }
  };

  const handleDeleteJob = async (jobId) => {
    if (window.confirm('Are you sure you want to delete this job posting?')) {
      try {
        await api.delete(`/jobs/${jobId}`); // Token is attached by Axios
        toast.success('Job deleted successfully');
        fetchJobs();
      } catch (err) {
        toast.error('Error deleting job');
      }
    }
  };

  return (
    <Container className="py-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>Job Postings</h2>
        <Button variant="primary" onClick={() => setShowForm(true)}>
          Create New Job
        </Button>
      </div>

      <Card>
        <Card.Body>
          {loading ? (
            <div className="text-center py-4">Loading...</div>
          ) : jobs.length === 0 ? (
            <div className="text-center py-4">
              <p>No job postings yet</p>
              <Button variant="primary" onClick={() => setShowForm(true)}>
                Create Your First Job Posting
              </Button>
            </div>
          ) : (
            <Table responsive hover>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Posted Date</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {jobs.map((job) => (
                  <tr key={job._id}>
                    <td>{job.title}</td>
                    <td>{new Date(job.createdAt).toLocaleDateString()}</td>
                    <td>{job.status}</td>
                    <td>
                      <Button
                        variant="outline-primary"
                        size="sm"
                        className="me-2"
                        onClick={() => {
                          setEditingJob(job);
                          setShowForm(true);
                        }}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="outline-danger"
                        size="sm"
                        onClick={() => handleDeleteJob(job._id)}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Card.Body>
      </Card>

      <Modal
        show={showForm}
        onHide={() => {
          setShowForm(false);
          setEditingJob(null);
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {editingJob ? 'Edit Job Posting' : 'Create New Job Posting'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <JobPostingForm
            initialData={editingJob}
            onSubmit={editingJob ? handleUpdateJob : handleCreateJob}
            onCancel={() => {
              setShowForm(false);
              setEditingJob(null);
            }}
          />
        </Modal.Body>
      </Modal>
    </Container>
  );
}
