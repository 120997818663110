// Pages/AdminDashboard/AdminDashboard.js
import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, Table, Badge, Form } from 'react-bootstrap';
import { Line, Pie } from 'recharts';
import './adminDashboard.css';

const AdminDashboard = () => {
  const [activeTab, setActiveTab] = useState('overview');
  const [searchTerm, setSearchTerm] = useState('');

  // Sample data for charts
  const userActivityData = [
    { name: 'Jan', employees: 65, companies: 35 },
    { name: 'Feb', employees: 75, companies: 42 },
    { name: 'Mar', employees: 95, companies: 55 },
    { name: 'Apr', employees: 120, companies: 68 },
    { name: 'May', employees: 150, companies: 82 },
  ];

  const matchingData = [
    { name: 'Successful Matches', value: 68 },
    { name: 'Pending', value: 22 },
    { name: 'Failed', value: 10 },
  ];

  // Sample data for tables
  const recentUsers = [
    { id: 1, name: 'John Doe', type: 'employee', status: 'active', date: '2024-03-20' },
    { id: 2, name: 'Tech Corp', type: 'company', status: 'pending', date: '2024-03-19' },
    // Add more sample data
  ];

  const renderOverviewTab = () => (
    <>
      <Row className="mb-4">
        <Col md={3}>
          <Card className="stat-card">
            <Card.Body>
              <div className="stat-icon bg-primary">
                <i className="bi bi-people"></i>
              </div>
              <h3>2,547</h3>
              <p>Total Users</p>
              <div className="stat-footer">
                <span className="text-success">↑ 12%</span> vs last month
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="stat-card">
            <Card.Body>
              <div className="stat-icon bg-success">
                <i className="bi bi-building"></i>
              </div>
              <h3>486</h3>
              <p>Active Companies</p>
              <div className="stat-footer">
                <span className="text-success">↑ 8%</span> vs last month
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="stat-card">
            <Card.Body>
              <div className="stat-icon bg-info">
                <i className="bi bi-briefcase"></i>
              </div>
              <h3>1,284</h3>
              <p>Active Jobs</p>
              <div className="stat-footer">
                <span className="text-success">↑ 15%</span> vs last month
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="stat-card">
            <Card.Body>
              <div className="stat-icon bg-warning">
                <i className="bi bi-graph-up"></i>
              </div>
              <h3>68%</h3>
              <p>Match Rate</p>
              <div className="stat-footer">
                <span className="text-success">↑ 5%</span> vs last month
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col md={8}>
          <Card className="chart-card">
            <Card.Body>
              <Card.Title>User Activity</Card.Title>
              <Line
                data={userActivityData}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                {/* Add chart components */}
              </Line>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="chart-card">
            <Card.Body>
              <Card.Title>Matching Statistics</Card.Title>
              <Pie
                data={matchingData}
                cx="50%"
                cy="50%"
                label
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Card className="recent-activity-card">
        <Card.Body>
          <Card.Title className="d-flex justify-content-between align-items-center">
            Recent Activity
            <Button variant="outline-primary" size="sm">View All</Button>
          </Card.Title>
          <div className="activity-timeline">
            {/* Activity items */}
            <div className="activity-item">
              <div className="activity-icon bg-success">
                <i className="bi bi-person-plus"></i>
              </div>
              <div className="activity-content">
                <p>New company registration: <strong>Tech Solutions Ltd</strong></p>
                <small className="text-muted">2 hours ago</small>
              </div>
            </div>
            {/* Add more activity items */}
          </div>
        </Card.Body>
      </Card>
    </>
  );

  const renderUsersTab = () => (
    <Card>
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <Form.Control
            type="search"
            placeholder="Search users..."
            className="w-25"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div>
            <Button variant="outline-secondary" className="me-2">Filter</Button>
            <Button variant="outline-primary">Export</Button>
          </div>
        </div>
        <Table responsive hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Status</th>
              <th>Joined Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {recentUsers.map(user => (
              <tr key={user.id}>
                <td>{user.name}</td>
                <td>
                  <Badge bg={user.type === 'employee' ? 'info' : 'primary'}>
                    {user.type}
                  </Badge>
                </td>
                <td>
                  <Badge bg={user.status === 'active' ? 'success' : 'warning'}>
                    {user.status}
                  </Badge>
                </td>
                <td>{new Date(user.date).toLocaleDateString()}</td>
                <td>
                  <Button variant="link" size="sm" className="me-2">Edit</Button>
                  <Button variant="link" size="sm" className="text-danger">Delete</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );

  return (
    <div className="admin-dashboard">
      <Container fluid>
        {/* Admin Header */}
        <div className="admin-header mb-4">
          <h1>Admin Dashboard</h1>
          <div className="admin-actions">
            <Button variant="outline-primary" className="me-2">
              <i className="bi bi-gear"></i> Settings
            </Button>
            <Button variant="outline-secondary">
              <i className="bi bi-download"></i> Generate Report
            </Button>
          </div>
        </div>

        {/* Navigation Tabs */}
        <div className="admin-tabs mb-4">
          <Button
            variant={activeTab === 'overview' ? 'primary' : 'light'}
            onClick={() => setActiveTab('overview')}
          >
            Overview
          </Button>
          <Button
            variant={activeTab === 'users' ? 'primary' : 'light'}
            onClick={() => setActiveTab('users')}
          >
            Users
          </Button>
          <Button
            variant={activeTab === 'jobs' ? 'primary' : 'light'}
            onClick={() => setActiveTab('jobs')}
          >
            Jobs
          </Button>
          <Button
            variant={activeTab === 'reports' ? 'primary' : 'light'}
            onClick={() => setActiveTab('reports')}
          >
            Reports
          </Button>
          <Button
            variant={activeTab === 'settings' ? 'primary' : 'light'}
            onClick={() => setActiveTab('settings')}
          >
            Settings
          </Button>
        </div>

        {/* Tab Content */}
        {activeTab === 'overview' && renderOverviewTab()}
        {activeTab === 'users' && renderUsersTab()}
        {/* Add other tab contents */}
      </Container>
    </div>
  );
};

export default AdminDashboard;