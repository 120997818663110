// components/CandidateModal.js
import React from 'react';
import { Modal, Badge, Row, Col } from 'react-bootstrap';

const CandidateModal = ({ show, onHide, candidate }) => {
  if (!candidate) return null;

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{candidate.name}'s Profile</Modal.Title>
      </Modal.Header>
      
      <Modal.Body>
        <Row>
          {/* Personal Info */}
          <Col md={6} className="mb-4">
            <h5>Personal Information</h5>
            {candidate.cv.personalInfo && (
              <div className="text-muted">
                <p>Location: {candidate.cv.personalInfo.address}</p>
                {candidate.cv.personalInfo.linkedIn && (
                  <p>LinkedIn: {candidate.cv.personalInfo.linkedIn}</p>
                )}
              </div>
            )}
          </Col>

          {/* Skills */}
          <Col md={6} className="mb-4">
            <h5>Skills</h5>
            <div>
              {candidate.cv.skills?.map((skill, index) => (
                <Badge 
                  key={index} 
                  bg={skill.level === 'Expert' ? 'primary' : 
                     skill.level === 'Advanced' ? 'success' : 
                     skill.level === 'Intermediate' ? 'info' : 'secondary'}
                  className="me-1 mb-1"
                >
                  {skill.name} ({skill.level})
                </Badge>
              ))}
            </div>
          </Col>
        </Row>

        {/* Experience */}
        <div className="mb-4">
          <h5>Experience</h5>
          {candidate.cv.experience?.map((exp, index) => (
            <div key={index} className="mb-3">
              <h6>{exp.position} at {exp.company}</h6>
              <div className="text-muted small">
                {new Date(exp.startDate).toLocaleDateString()} - 
                {exp.current ? 'Present' : new Date(exp.endDate).toLocaleDateString()}
              </div>
              <p className="mb-0">{exp.description}</p>
            </div>
          ))}
        </div>

        {/* Education */}
        <div className="mb-4">
          <h5>Education</h5>
          {candidate.cv.education?.map((edu, index) => (
            <div key={index} className="mb-3">
              <h6>{edu.degree} in {edu.fieldOfStudy}</h6>
              <div className="text-muted">
                {edu.institution}
                <br />
                {new Date(edu.startDate).toLocaleDateString()} - 
                {edu.current ? 'Present' : new Date(edu.endDate).toLocaleDateString()}
              </div>
            </div>
          ))}
        </div>

        {/* Languages */}
        <div>
          <h5>Languages</h5>
          {candidate.cv.languages?.map((lang, index) => (
            <Badge 
              key={index}
              bg="light" 
              text="dark"
              className="me-1 mb-1"
            >
              {lang.name} ({lang.level})
            </Badge>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CandidateModal;