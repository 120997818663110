import React from 'react';
import { Card, ListGroup, Badge } from 'react-bootstrap';

const EvaluationHistory = ({ evaluations, onSelect, currentEvaluationId }) => {
  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });
  };

  const getStatusBadge = (status) => {
    switch (status) {
      case 'completed':
        return <Badge bg="success">Completed</Badge>;
      case 'pending':
        return <Badge bg="warning">Pending</Badge>;
      case 'failed':
        return <Badge bg="danger">Failed</Badge>;
      default:
        return <Badge bg="secondary">{status}</Badge>;
    }
  };

  return (
    <Card>
      <Card.Header>
        <h5 className="mb-0">Evaluation History</h5>
      </Card.Header>
      <ListGroup variant="flush">
        {evaluations.length === 0 ? (
          <ListGroup.Item className="text-center text-muted py-4">
            No evaluations yet
          </ListGroup.Item>
        ) : (
          evaluations.map((evaluation) => (
            <ListGroup.Item
              key={evaluation._id}
              action
              active={evaluation._id === currentEvaluationId}
              onClick={() => onSelect(evaluation._id)}
              className="d-flex justify-content-between align-items-start"
            >
              <div className="ms-2 me-auto">
                <div className="fw-bold">
                  {formatDate(evaluation.createdAt)}
                </div>
                <div className="small text-muted">
                  Market Fit: {evaluation.evaluation?.marketFit?.score || 'N/A'}%
                </div>
              </div>
              {getStatusBadge(evaluation.status)}
            </ListGroup.Item>
          ))
        )}
      </ListGroup>
    </Card>
  );
};

export default EvaluationHistory;