import React from 'react';
import { Form } from 'react-bootstrap';

export default function PersonalInfoForm({ data, onUpdate }) {
  const handleChange = (e) => {
    onUpdate('personalInfo', {
      ...data.personalInfo,
      [e.target.name]: e.target.value
    });
  };

  return (
    <div>
      <Form.Group className="mb-3">
        <Form.Label>First Name</Form.Label>
        <Form.Control
          type="text"
          name="firstName"
          value={data.personalInfo?.firstName || ''}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Last Name</Form.Label>
        <Form.Control
          type="text"
          name="lastName"
          value={data.personalInfo?.lastName || ''}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Phone Number</Form.Label>
        <Form.Control
          type="tel"
          name="phoneNumber"
          value={data.personalInfo?.phoneNumber || ''}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>LinkedIn Profile</Form.Label>
        <Form.Control
          type="url"
          name="linkedIn"
          value={data.personalInfo?.linkedIn || ''}
          onChange={handleChange}
          placeholder="https://linkedin.com/in/yourprofile"
        />
      </Form.Group>
    </div>
  );
}