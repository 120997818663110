// components/CVCard.js
import React from 'react';
import { Card, Badge, Button } from 'react-bootstrap';

const CVCard = ({ candidate, onView }) => {
  const getCurrentPosition = () => {
    const currentJob = candidate.cv?.experience?.find(exp => exp.current);
    return currentJob ? `${currentJob.position} at ${currentJob.company}` : 'Not specified';
  };

  if (!candidate?.cv) {
    return null;
  }

  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title className="d-flex justify-content-between align-items-center">
          <div>
            {candidate.name}
            <div className="text-muted small">{getCurrentPosition()}</div>
          </div>
          <Button 
            variant="outline-primary" 
            size="sm"
            onClick={() => onView(candidate)}
          >
            View Profile
          </Button>
        </Card.Title>

        {/* Skills */}
        <div className="mb-2">
          {candidate.cv.skills?.map((skill, index) => (
            <Badge 
              key={index} 
              bg={skill.level === 'Expert' ? 'primary' : 
                 skill.level === 'Advanced' ? 'success' : 
                 skill.level === 'Intermediate' ? 'info' : 'secondary'}
              className="me-1 mb-1"
            >
              {skill.name} ({skill.level})
            </Badge>
          ))}
        </div>

        {/* Languages */}
        <div className="mb-2">
          {candidate.cv.languages?.map((lang, index) => (
            <Badge 
              key={index}
              bg="light" 
              text="dark"
              className="me-1 mb-1"
            >
              {lang.name} ({lang.level})
            </Badge>
          ))}
        </div>

        {/* Education */}
        <div className="small text-muted">
          {candidate.cv.education?.[0] && (
            <div>
              {candidate.cv.education[0].degree} in {candidate.cv.education[0].fieldOfStudy}
              <br />
              {candidate.cv.education[0].institution}
            </div>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default CVCard;