import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const AboutPage = () => {
  const teamMembers = [
    {
      name: "Sarah Chen",
      role: "Founder & CEO",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum vestibulum."
    },
    {
      name: "James Wilson",
      role: "Lead Developer",
      bio: "Praesent dapibus, neque id cursus faucibus, tortor neque egestas augue, eu vulputate magna eros eu erat."
    },
    {
      name: "Maria Garcia",
      role: "Design Director",
      bio: "Aliquam tincidunt mauris eu risus. Vestibulum auctor dapibus neque."
    }
  ];

  return (
    <div className="bg-light min-vh-100 py-5">
      <Container>
        {/* Hero Section */}
        <Row className="mb-5 text-center">
          <Col>
            <h1 className="display-4 mb-4">Crafting Digital Excellence</h1>
            <p className="lead text-muted">
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, 
              totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
            </p>
          </Col>
        </Row>

        {/* Mission Statement */}
        <Row className="mb-5">
          <Col md={6} className="mb-4 mb-md-0">
            <Card className="border-0 shadow-sm h-100">
              <Card.Body className="p-4">
                <h3 className="h4 mb-3">Our Mission</h3>
                <p>
                  Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, 
                  sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="border-0 shadow-sm h-100 bg-primary text-white">
              <Card.Body className="p-4">
                <h3 className="h4 mb-3">Our Vision</h3>
                <p>
                  Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, 
                  adipisci velit, sed quia non numquam eius modi tempora.
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Team Section */}
        <h2 className="text-center mb-4">Meet Our Team</h2>
        <Row>
          {teamMembers.map((member, index) => (
            <Col key={index} md={4} className="mb-4">
              <Card className="border-0 shadow-sm h-100">
                <Card.Body className="p-4 text-center">
                  <div className="mb-3">
                    <img
                      src={`/api/placeholder/150/150`}
                      alt={member.name}
                      className="rounded-circle"
                      width="100"
                      height="100"
                    />
                  </div>
                  <h4 className="h5 mb-2">{member.name}</h4>
                  <p className="text-muted small mb-3">{member.role}</p>
                  <p className="small">{member.bio}</p>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>

        {/* Stats Section */}
        <Row className="mt-5 text-center">
          <Col md={4} className="mb-4 mb-md-0">
            <h2 className="display-4 fw-bold text-primary">100+</h2>
            <p className="text-muted">Projects Completed</p>
          </Col>
          <Col md={4} className="mb-4 mb-md-0">
            <h2 className="display-4 fw-bold text-primary">50+</h2>
            <p className="text-muted">Happy Clients</p>
          </Col>
          <Col md={4}>
            <h2 className="display-4 fw-bold text-primary">5+</h2>
            <p className="text-muted">Years of Experience</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AboutPage;