import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Alert, Spinner } from 'react-bootstrap';
import api from '../../config/axios';
import EvaluationHistory from '../../components/evaluation/evaluationHistory';
import EvaluationResult from '../../components/evaluation/evaluationResult';
import { toast } from 'react-toastify';

const MyEvaluationPage = () => {
  const [loading, setLoading] = useState(false);
  const [evaluationHistory, setEvaluationHistory] = useState([]);
  const [currentEvaluation, setCurrentEvaluation] = useState(null);
  const [canRequestEvaluation, setCanRequestEvaluation] = useState(false);
  const [profileStatus, setProfileStatus] = useState({
    cvComplete: false,
    questionsComplete: false
  });

  // Fetch initial data
  useEffect(() => {
    fetchProfileStatus();
    fetchEvaluationHistory();
  }, []);

  // Check if user can request evaluation
  const fetchProfileStatus = async () => {
    try {
      // Use the same approach as ProfileReminder
      const cvResponse = await api.get('/users/cv');
      const profileResponse = await api.get('/users/profile');
      
      console.log('CV Response:', cvResponse.data);
      console.log('Profile Response:', profileResponse.data);

      const status = {
        cvComplete: !(!cvResponse.data?.cv || cvResponse.data.cv.status !== 'completed'),
        questionsComplete: !(!profileResponse.data?.user?.employeeProfile || 
                           profileResponse.data.user.employeeProfile.length === 0)
      };

      setProfileStatus(status);
      setCanRequestEvaluation(status.cvComplete && status.questionsComplete);

      console.log('Profile Status:', status);
      console.log('Can Request Evaluation:', status.cvComplete && status.questionsComplete);

    } catch (error) {
      console.error('Error checking profile status:', error);
      toast.error('Error checking profile status');
    }
  };

  // Fetch evaluation history
  const fetchEvaluationHistory = async () => {
    try {
      const { data } = await api.get('/evaluations/history');
      console.log('Evaluation History:', data);
      setEvaluationHistory(data);
    } catch (error) {
      console.error('Error fetching evaluation history:', error);
      toast.error('Error fetching evaluation history');
    }
  };

  // Request new evaluation
  const requestEvaluation = async () => {
    try {
      setLoading(true);
      const { data } = await api.post('/evaluations/request');
      setCurrentEvaluation(data);
      await fetchEvaluationHistory(); // Refresh history
      toast.success('Evaluation completed successfully!');
    } catch (error) {
      console.error('Error requesting evaluation:', error);
      toast.error(error.response?.data?.message || 'Error requesting evaluation');
    } finally {
      setLoading(false);
    }
  };

  // View specific evaluation
  const viewEvaluation = async (evaluationId) => {
    try {
      const { data } = await api.get(`/evaluations/${evaluationId}`);
      setCurrentEvaluation(data);
    } catch (error) {
      console.error('Error fetching evaluation details:', error);
      toast.error('Error fetching evaluation details');
    }
  };

  return (
    <Container className="py-4">
      <h1 className="mb-4">My AI Profile Evaluation</h1>

      {/* Profile Status Messages */}
      {!canRequestEvaluation && (
        <Alert variant="warning" className="mb-4">
          <Alert.Heading>Complete Your Profile First</Alert.Heading>
          <p>Before requesting an AI evaluation, please complete:</p>
          <ul className="mb-0">
            {!profileStatus.cvComplete && (
              <li>Your CV must be completed and submitted</li>
            )}
            {!profileStatus.questionsComplete && (
              <li>Personal Profile Questions need to be answered</li>
            )}
          </ul>
        </Alert>
      )}

      {/* Request New Evaluation Section */}
      <Card className="mb-4">
        <Card.Body>
          <Row className="align-items-center">
            <Col>
              <h4 className="mb-0">Request New Evaluation</h4>
              <p className="text-muted mb-0">
                Get an AI-powered analysis of your professional profile
              </p>
            </Col>
            <Col xs="auto">
              <Button
                variant="primary"
                onClick={requestEvaluation}
                disabled={loading || !canRequestEvaluation}
              >
                {loading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="me-2"
                    />
                    Processing...
                  </>
                ) : (
                  'Request Evaluation'
                )}
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Row>
        {/* Current/Latest Evaluation Display */}
        <Col md={8}>
          {currentEvaluation && (
            <EvaluationResult evaluation={currentEvaluation} />
          )}
        </Col>

        {/* Evaluation History Sidebar */}
        <Col md={4}>
          <EvaluationHistory
            evaluations={evaluationHistory}
            onSelect={viewEvaluation}
            currentEvaluationId={currentEvaluation?._id}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default MyEvaluationPage;