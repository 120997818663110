// Pages/SettingsPage.js
import React, { useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';

export default function SettingsPage() {
  const [settings, setSettings] = useState({ emailNotifications: true, accountPrivacy: 'public' });

  const handleChange = (e) => {
    setSettings({ ...settings, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle settings update logic here
    console.log(settings);
  };

  return (
    <Container className="mt-3">
      <h1>Settings</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="emailNotifications">
          <Form.Label>Email Notifications</Form.Label>
          <Form.Check
            type="switch"
            name="emailNotifications"
            checked={settings.emailNotifications}
            onChange={(e) => setSettings({ ...settings, emailNotifications: e.target.checked })}
            label="Enable email notifications"
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="accountPrivacy">
          <Form.Label>Account Privacy</Form.Label>
          <Form.Select name="accountPrivacy" value={settings.accountPrivacy} onChange={handleChange}>
            <option value="public">Public</option>
            <option value="private">Private</option>
          </Form.Select>
        </Form.Group>
        <Button type="submit">Save Settings</Button>
      </Form>
    </Container>
  );
}
