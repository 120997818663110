// Pages/CVUploadPage.js
import React, { useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';

export default function CVUploadPage() {
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle file upload logic here
    console.log(file);
  };

  return (
    <Container className="mt-3">
      <h1>CV Upload</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formFile">
          <Form.Label>Upload Your CV</Form.Label>
          <Form.Control type="file" onChange={handleFileChange} required />
        </Form.Group>
        <Button type="submit">Upload</Button>
      </Form>
    </Container>
  );
}
