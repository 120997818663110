// NavBarPersonal.js
import { Link } from 'react-router-dom';
import NavBar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import { LinkContainer } from 'react-router-bootstrap';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Store } from '../../context/context';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';


function NavBarPersonal() {
  const navigate = useNavigate();
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;


  const signoutHandler = () => {
    ctxDispatch({ type: 'USER_SIGNOUT' });
    localStorage.removeItem('userInfo');
    navigate('/');
  };

  return (
    <div className="d-flex flex-column site-container">
      <ToastContainer position="bottom-center" limit={1} />
      <header>
        <NavBar bg="dark" variant="dark">
          <Container>
            <LinkContainer to="/my-dashboard">
              <NavBar.Brand>My Dashboard</NavBar.Brand>
            </LinkContainer>
            <LinkContainer to="/create-cv">
              <NavBar.Brand>create cv</NavBar.Brand>
            </LinkContainer>
            <LinkContainer to="/cv-upload">
              <NavBar.Brand className="nav-link">CV Įkėlimas</NavBar.Brand>
            </LinkContainer>
            <LinkContainer to="/all-jobs">
              <NavBar.Brand className="nav-link">All Jobs</NavBar.Brand>
            </LinkContainer>
            <LinkContainer to="/ai-jobs">
              <NavBar.Brand className="nav-link">Jobs Match by AI</NavBar.Brand>
            </LinkContainer>
            <LinkContainer to={`/my-evaluation`}>
              <NavBar.Brand className="nav-link"> My Evaluation </NavBar.Brand>
            </LinkContainer>

            {/* dropdown menu after user login below */}
            <Nav className="me-auto">
              {userInfo ? (
                <NavDropdown title={userInfo.name} id="basic-nav-dropdown">
                  <LinkContainer to="/questionnaire">
                    <NavDropdown.Item>
                      Personal Profile Assessment
                    </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/settings">
                    <NavDropdown.Item>Settings</NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Divider />
                  <Link
                    className="dropdown-item"
                    to="#"
                    onClick={signoutHandler}
                  >
                    Logout
                  </Link>
                </NavDropdown>
              ) : (
                <Link className="nav-link" to="/login">
                  Login
                </Link>
              )}
            </Nav>
          </Container>
        </NavBar>
      </header>
    </div>
  );
}

export default NavBarPersonal;
