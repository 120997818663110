import React from 'react';
import { Card, Badge, Row, Col, ProgressBar } from 'react-bootstrap';

const EvaluationResult = ({ evaluation }) => {
  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  return (
    <div className="evaluation-result">
      <Card className="mb-4">
        <Card.Header className="bg-primary text-white">
          <h4 className="mb-0">Evaluation Results</h4>
          <small>{formatDate(evaluation.createdAt)}</small>
        </Card.Header>
        <Card.Body>
          {/* Market Fit Score */}
          <div className="mb-4">
            <h5>Market Fit Score</h5>
            <ProgressBar
              now={evaluation.evaluation.marketFit.score}
              label={`${evaluation.evaluation.marketFit.score}%`}
              variant={
                evaluation.evaluation.marketFit.score >= 80 ? 'success' :
                evaluation.evaluation.marketFit.score >= 60 ? 'info' :
                'warning'
              }
              className="mb-2"
            />
            <p className="text-muted">{evaluation.evaluation.marketFit.analysis}</p>
          </div>

          {/* Strengths and Improvements */}
          <Row className="mb-4">
            <Col md={6}>
              <h5>Key Strengths</h5>
              <ul className="list-unstyled">
                {evaluation.evaluation.strengths.map((strength, index) => (
                  <li key={index} className="mb-2">
                    <i className="bi bi-check-circle-fill text-success me-2"></i>
                    {strength}
                  </li>
                ))}
              </ul>
            </Col>
            <Col md={6}>
              <h5>Areas for Improvement</h5>
              <ul className="list-unstyled">
                {evaluation.evaluation.improvements.map((improvement, index) => (
                  <li key={index} className="mb-2">
                    <i className="bi bi-arrow-up-circle-fill text-warning me-2"></i>
                    {improvement}
                  </li>
                ))}
              </ul>
            </Col>
          </Row>

          {/* Skills Analysis */}
          <div className="mb-4">
            <h5>Skills Analysis</h5>
            <Row>
              <Col md={6}>
                <Card className="bg-light">
                  <Card.Body>
                    <h6>Technical Skills</h6>
                    <div className="mb-3">
                      <small className="text-muted">Strong:</small>
                      <div>
                        {evaluation.evaluation.skills.technical.strong.map((skill, index) => (
                          <Badge bg="success" className="me-2 mb-2" key={index}>
                            {skill}
                          </Badge>
                        ))}
                      </div>
                    </div>
                    <div>
                      <small className="text-muted">Needs Improvement:</small>
                      <div>
                        {evaluation.evaluation.skills.technical.needsImprovement.map((skill, index) => (
                          <Badge bg="warning" text="dark" className="me-2 mb-2" key={index}>
                            {skill}
                          </Badge>
                        ))}
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6}>
                <Card className="bg-light">
                  <Card.Body>
                    <h6>Soft Skills</h6>
                    <div className="mb-3">
                      <small className="text-muted">Strong:</small>
                      <div>
                        {evaluation.evaluation.skills.soft.strong.map((skill, index) => (
                          <Badge bg="success" className="me-2 mb-2" key={index}>
                            {skill}
                          </Badge>
                        ))}
                      </div>
                    </div>
                    <div>
                      <small className="text-muted">Needs Improvement:</small>
                      <div>
                        {evaluation.evaluation.skills.soft.needsImprovement.map((skill, index) => (
                          <Badge bg="warning" text="dark" className="me-2 mb-2" key={index}>
                            {skill}
                          </Badge>
                        ))}
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>

          {/* Career Path Suggestions */}
          <div className="mb-4">
            <h5>Career Path Suggestions</h5>
            {evaluation.evaluation.careerPath.suggested.map((path, index) => (
              <Card className="mb-2" key={index}>
                <Card.Body>
                  <h6>{path}</h6>
                  <p className="mb-0 text-muted">
                    {evaluation.evaluation.careerPath.reasons[index]}
                  </p>
                </Card.Body>
              </Card>
            ))}
          </div>

          {/* Recommendations */}
          <div className="mb-4">
            <h5>Action Plan</h5>
            <Row>
              <Col md={6}>
                <h6 className="text-primary">Immediate Steps</h6>
                <ul>
                  {evaluation.evaluation.recommendations.immediate.map((rec, index) => (
                    <li key={index}>{rec}</li>
                  ))}
                </ul>
              </Col>
              <Col md={6}>
                <h6 className="text-primary">Long-term Goals</h6>
                <ul>
                  {evaluation.evaluation.recommendations.longTerm.map((rec, index) => (
                    <li key={index}>{rec}</li>
                  ))}
                </ul>
              </Col>
            </Row>
          </div>

          {/* Overall Summary */}
          <div>
            <h5>Overall Summary</h5>
            <p className="mb-0">{evaluation.evaluation.overallSummary}</p>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default EvaluationResult;