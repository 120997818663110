// services/aiService.js
import api from '../config/axios';

export const getAIJobMatches = async (request) => {
  try {
    console.log('Sending AI job match request:', { request });
    const { data } = await api.post('/ai/match/jobs', {
      request: request
    });
    console.log('AI job match response:', data);
    return data;
  } catch (error) {
    console.error('AI Job Match Error:', error);
    throw error;
  }
};

export const getAICandidateMatches = async (request) => {
  try {
    // Input validation
    if (!request?.trim()) {
      throw new Error('Please provide a search request');
    }

    // Get and validate company authentication
    const companyInfo = localStorage.getItem('companyInfo');
    if (!companyInfo) {
      throw new Error('Company authentication required. Please log in.');
    }

    let parsedCompanyInfo;
    try {
      parsedCompanyInfo = JSON.parse(companyInfo);
    } catch (parseError) {
      console.error('Company info parse error:', parseError);
      throw new Error('Invalid company session. Please log in again.');
    }

    if (!parsedCompanyInfo.token) {
      throw new Error('Invalid authentication token. Please log in again.');
    }

    // Make API request with detailed logging
    console.log('Initiating AI candidate match request');
    const response = await api.post(
      '/ai/match/candidates',
      { request },
      {
        headers: {
          'Authorization': `Bearer ${parsedCompanyInfo.token}`
        }
      }
    );

    // Validate response
    if (!response.data) {
      throw new Error('No data received from server');
    }

    console.log('AI match request successful');
    return response.data;

  } catch (error) {
    console.error('AI Candidate Match Error:', {
      message: error.message,
      status: error.response?.status,
      serverError: error.response?.data?.message
    });

    // Transform error messages for user display
    if (error.response?.status === 401) {
      throw new Error('Session expired. Please log in again.');
    }
    
    if (error.response?.status === 404) {
      throw new Error('No matching candidates found.');
    }

    if (error.response?.status === 500) {
      throw new Error('Server error. Please try again later.');
    }

    // Use server message if available, otherwise use local error message
    throw new Error(
      error.response?.data?.message || 
      error.message || 
      'Failed to process candidate matching'
    );
  }
};