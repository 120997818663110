import React, { useState } from 'react';
import { Card, Button } from 'react-bootstrap';

const EditableCard = ({ 
  data,
  onEdit,
  onDelete,
  renderDisplay,
  renderEditForm,
  className = ''
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState(data);

  const handleEditSubmit = () => {
    onEdit(editedData);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setEditedData(data);
    setIsEditing(false);
  };

  const handleChange = (updatedData) => {
    setEditedData(updatedData);
  };

  return (
    <Card className={`mb-3 ${className}`}>
      <Card.Body>
        {isEditing ? (
          <>
            {renderEditForm(editedData, handleChange, handleCancel, handleEditSubmit)}
          </>
        ) : (
          <div className="d-flex justify-content-between align-items-start">
            <div className="flex-grow-1">
              {renderDisplay(data)}
            </div>
            <div className="ms-3">
              <Button
                variant="outline-primary"
                size="sm"
                className="me-2"
                onClick={() => {
                  setEditedData(data);
                  setIsEditing(true);
                }}
              >
                <i className="bi bi-pencil"></i>
              </Button>
              <Button
                variant="outline-danger"
                size="sm"
                onClick={() => onDelete()}
              >
                <i className="bi bi-trash"></i>
              </Button>
            </div>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default EditableCard;