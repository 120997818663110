import React, { useState } from 'react';
import { Form, Button, Card, Row, Col } from 'react-bootstrap';
import EditableCard from './components/editableCard';

const ExperienceForm = ({ data, onUpdate }) => {
  const [newExperience, setNewExperience] = useState({
    company: '',
    position: '',
    location: '',
    startDate: '',
    endDate: '',
    current: false,
    description: '',
  });

  const handleAdd = () => {
    if (newExperience.company && newExperience.position) {
      onUpdate('experience', [...(data.experience || []), newExperience]);
      setNewExperience({
        company: '',
        position: '',
        location: '',
        startDate: '',
        endDate: '',
        current: false,
        description: '',
      });
    }
  };

  const handleEdit = (index, updatedExp) => {
    const updatedExperiences = [...data.experience];
    updatedExperiences[index] = updatedExp;
    onUpdate('experience', updatedExperiences);
  };

  const handleDelete = (index) => {
    const updatedExperiences = data.experience.filter((_, i) => i !== index);
    onUpdate('experience', updatedExperiences);
  };

  const renderExperienceForm = (experience, onChange, onCancel, onSubmit) => (
    <div>
      {' '}
      {/* Changed from Form to div */}
      <Row>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Company</Form.Label>
            <Form.Control
              type="text"
              value={experience.company}
              onChange={(e) =>
                onChange({ ...experience, company: e.target.value })
              }
              required
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Position</Form.Label>
            <Form.Control
              type="text"
              value={experience.position}
              onChange={(e) =>
                onChange({ ...experience, position: e.target.value })
              }
              required
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Start Date</Form.Label>
            <Form.Control
              type="date"
              value={experience.startDate}
              onChange={(e) =>
                onChange({ ...experience, startDate: e.target.value })
              }
              required
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              label="I currently work here"
              checked={experience.current}
              onChange={(e) =>
                onChange({ ...experience, current: e.target.checked })
              }
              className="mb-2"
            />
            {!experience.current && (
              <Form.Control
                type="date"
                value={experience.endDate}
                onChange={(e) =>
                  onChange({ ...experience, endDate: e.target.value })
                }
                placeholder="End Date"
              />
            )}
          </Form.Group>
        </Col>
      </Row>
      <Form.Group className="mb-3">
        <Form.Label>Description</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          value={experience.description}
          onChange={(e) =>
            onChange({ ...experience, description: e.target.value })
          }
        />
      </Form.Group>
      <div className="d-flex justify-content-end gap-2">
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="primary" onClick={onSubmit}>
          Save
        </Button>
      </div>
    </div>
  );

  const renderExperienceDisplay = (experience) => (
    <>
      <h5 className="mb-1">{experience.position}</h5>
      <h6 className="mb-2 text-muted">{experience.company}</h6>
      <p className="mb-2 text-muted">
        {new Date(experience.startDate).toLocaleDateString()} -
        {experience.current
          ? 'Present'
          : new Date(experience.endDate).toLocaleDateString()}
      </p>
      {experience.description && (
        <p className="mb-0">{experience.description}</p>
      )}
    </>
  );

  return (
    <div>
      {data.experience?.map((experience, index) => (
        <EditableCard
          key={index}
          data={experience}
          onEdit={(updatedExp) => handleEdit(index, updatedExp)}
          onDelete={() => handleDelete(index)}
          renderDisplay={renderExperienceDisplay}
          renderEditForm={renderExperienceForm}
        />
      ))}

      <Card className="mb-3">
        <Card.Body>
          <Card.Title>Add New Experience</Card.Title>
          {renderExperienceForm(
            newExperience,
            setNewExperience,
            () =>
              setNewExperience({
                company: '',
                position: '',
                location: '',
                startDate: '',
                endDate: '',
                current: false,
                description: '',
              }),
            handleAdd
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default ExperienceForm;
