import React from 'react';

const CVStepper = ({ currentStep, setCurrentStep, cvData }) => {
  const steps = [
    { key: 'PERSONAL_INFO', label: 'Personal Info' },
    { key: 'EXPERIENCE', label: 'Experience' },
    { key: 'EDUCATION', label: 'Education' },
    { key: 'SKILLS', label: 'Skills' },
    { key: 'PROJECTS', label: 'Projects' },
    { key: 'REVIEW', label: 'Review' }
  ];

  // Moved step validation logic directly into the component
  const isStepComplete = (data, stepKey) => {
    switch (stepKey) {
      case 'PERSONAL_INFO':
        return !!(data?.personalInfo?.firstName && data?.personalInfo?.lastName);
      case 'EXPERIENCE':
        return !!(data?.experience && data.experience.length > 0);
      case 'EDUCATION':
        return !!(data?.education && data.education.length > 0);
      case 'SKILLS':
        return !!(data?.skills && data.skills.length > 0);
      case 'PROJECTS':
        // Projects are optional, so always return true
        return true;
      case 'REVIEW':
        // Review is always accessible if previous steps are complete
        return true;
      default:
        return false;
    }
  };

  const handleStepClick = (index) => {
    // Only allow clicking on completed steps or the next available step
    const canNavigate = steps.slice(0, index).every(step => 
      isStepComplete(cvData, step.key)
    );
    
    if (canNavigate || index < currentStep) {
      setCurrentStep(index);
    }
  };

  return (
    <div className="mb-5">
      <div className="d-flex justify-content-between align-items-center position-relative">
        {/* Progress Line */}
        <div 
          className="position-absolute h-1 bg-light" 
          style={{
            top: '50%',
            transform: 'translateY(-50%)',
            left: 0,
            right: 0,
            zIndex: 0
          }}
        >
          <div 
            className="h-100 bg-primary transition-all"
            style={{ 
              width: `${(currentStep / (steps.length - 1)) * 100}%`,
              transition: 'width 0.3s ease'
            }}
          />
        </div>

        {/* Steps */}
        <div className="d-flex justify-content-between w-100 position-relative" style={{ zIndex: 1 }}>
          {steps.map((step, index) => {
            const isCompleted = index < currentStep;
            const isCurrent = index === currentStep;
            const canClick = index <= currentStep || 
              steps.slice(0, index).every(s => isStepComplete(cvData, s.key));

            return (
              <div
                key={step.key}
                className="d-flex flex-column align-items-center"
                style={{ cursor: canClick ? 'pointer' : 'not-allowed' }}
                onClick={() => canClick && handleStepClick(index)}
              >
                {/* Step Circle */}
                <div
                  className={`
                    d-flex align-items-center justify-content-center
                    rounded-circle border-2 
                    ${isCompleted ? 'bg-primary border-primary' : 
                      isCurrent ? 'border-primary bg-white' : 
                      'border-secondary bg-white'}
                  `}
                  style={{ 
                    width: '32px', 
                    height: '32px',
                    transition: 'all 0.3s ease'
                  }}
                >
                  {isCompleted ? (
                    <i className="bi bi-check text-white" />
                  ) : (
                    <span className={isCurrent ? 'text-primary' : 'text-secondary'}>
                      {index + 1}
                    </span>
                  )}
                </div>

                {/* Step Label */}
                <span 
                  className={`
                    mt-2 small fw-medium
                    ${isCurrent ? 'text-primary' : 'text-secondary'}
                  `}
                >
                  {step.label}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CVStepper;