import React, { useState } from 'react';
import { Form, Button, Card, Row, Col } from 'react-bootstrap';
import EditableCard from './components/editableCard';

const EducationForm = ({ data, onUpdate }) => {
  const [newEducation, setNewEducation] = useState({
    institution: '',
    degree: '',
    fieldOfStudy: '',
    startDate: '',
    endDate: '',
    current: false,
    description: '',
  });

  const handleAdd = () => {
    if (newEducation.institution && newEducation.degree) {
      onUpdate('education', [...(data.education || []), newEducation]);
      setNewEducation({
        institution: '',
        degree: '',
        fieldOfStudy: '',
        startDate: '',
        endDate: '',
        current: false,
        description: '',
      });
    }
  };

  const handleEdit = (index, updatedEdu) => {
    const updatedEducation = [...data.education];
    updatedEducation[index] = updatedEdu;
    onUpdate('education', updatedEducation);
  };

  const handleDelete = (index) => {
    const updatedEducation = data.education.filter((_, i) => i !== index);
    onUpdate('education', updatedEducation);
  };

  const renderEducationForm = (education, onChange, onCancel, onSubmit) => (
    <div>
      <Form.Group className="mb-3">
        <Form.Label>Institution</Form.Label>
        <Form.Control
          type="text"
          value={education.institution}
          onChange={(e) =>
            onChange({ ...education, institution: e.target.value })
          }
          required
        />
      </Form.Group>

      <Row>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Degree</Form.Label>
            <Form.Control
              type="text"
              value={education.degree}
              onChange={(e) =>
                onChange({ ...education, degree: e.target.value })
              }
              required
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Field of Study</Form.Label>
            <Form.Control
              type="text"
              value={education.fieldOfStudy}
              onChange={(e) =>
                onChange({ ...education, fieldOfStudy: e.target.value })
              }
              required
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Start Date</Form.Label>
            <Form.Control
              type="date"
              value={education.startDate}
              onChange={(e) =>
                onChange({ ...education, startDate: e.target.value })
              }
              required
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              label="Currently studying"
              checked={education.current}
              onChange={(e) =>
                onChange({ ...education, current: e.target.checked })
              }
              className="mb-2"
            />
            {!education.current && (
              <Form.Control
                type="date"
                value={education.endDate}
                onChange={(e) =>
                  onChange({ ...education, endDate: e.target.value })
                }
                placeholder="End Date"
              />
            )}
          </Form.Group>
        </Col>
      </Row>

      <Form.Group className="mb-3">
        <Form.Label>Description</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          value={education.description}
          onChange={(e) =>
            onChange({ ...education, description: e.target.value })
          }
        />
      </Form.Group>

      <div className="d-flex justify-content-end gap-2">
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="primary" onClick={onSubmit}>
          Save
        </Button>
      </div>
    </div>
  );

  const renderEducationDisplay = (education) => (
    <>
      <h5 className="mb-1">
        {education.degree} in {education.fieldOfStudy}
      </h5>
      <h6 className="mb-2 text-muted">{education.institution}</h6>
      <p className="mb-2 text-muted">
        {new Date(education.startDate).toLocaleDateString()} -
        {education.current
          ? 'Present'
          : new Date(education.endDate).toLocaleDateString()}
      </p>
      {education.description && <p className="mb-0">{education.description}</p>}
    </>
  );

  return (
    <div>
      {data.education?.map((education, index) => (
        <EditableCard
          key={index}
          data={education}
          onEdit={(updatedEdu) => handleEdit(index, updatedEdu)}
          onDelete={() => handleDelete(index)}
          renderDisplay={renderEducationDisplay}
          renderEditForm={renderEducationForm}
        />
      ))}

      <Card className="mb-3">
        <Card.Body>
          <Card.Title>Add New Education</Card.Title>
          {renderEducationForm(
            newEducation,
            setNewEducation,
            () =>
              setNewEducation({
                institution: '',
                degree: '',
                fieldOfStudy: '',
                startDate: '',
                endDate: '',
                current: false,
                description: '',
              }),
            handleAdd
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default EducationForm;
