import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';

export default function SuccessStoriesPage() {
  return (
    <>
      <Helmet>
        <title>Success Stories - Our Platform</title>
      </Helmet>
      <Container className="my-5">
        <Row className="mb-4">
          <Col>
            <h1 className="text-center">Success Stories</h1>
            <p className="text-muted text-center">
              Real stories from people and companies who achieved their goals using our platform.
            </p>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col md={6} className="d-flex align-items-stretch">
            <Card className="bg-light border-0 shadow-sm mb-4">
              <Card.Img variant="top" src="https://via.placeholder.com/400x200" alt="Success Story 1" />
              <Card.Body>
                <Card.Title>From Freelancer to Founder</Card.Title>
                <Card.Text>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus luctus eros aliquet convallis ultricies. Mauris augue massa, ultricies non ligula.
                </Card.Text>
                <Button variant="success">Read Full Story</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} className="d-flex align-items-stretch">
            <Card className="bg-light border-0 shadow-sm mb-4">
              <Card.Img variant="top" src="https://via.placeholder.com/400x200" alt="Success Story 2" />
              <Card.Body>
                <Card.Title>A Small Business Goes Global</Card.Title>
                <Card.Text>
                  Donec sed odio dui. Nulla vitae elit libero, a pharetra augue. Nullam id dolor id nibh ultricies vehicula ut id elit. Aenean eu leo quam.
                </Card.Text>
                <Button variant="success">Read Full Story</Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col md={4} className="d-flex align-items-stretch">
            <Card className="bg-light border-0 shadow-sm mb-4">
              <Card.Body>
                <Card.Title>Breaking Into Tech</Card.Title>
                <Card.Text>
                  Curabitur blandit tempus porttitor. Integer posuere erat a ante venenatis dapibus posuere velit aliquet.
                </Card.Text>
                <Button variant="primary">Explore Story</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="d-flex align-items-stretch">
            <Card className="bg-light border-0 shadow-sm mb-4">
              <Card.Body>
                <Card.Title>Changing Career Paths</Card.Title>
                <Card.Text>
                  Etiam porta sem malesuada magna mollis euismod. Cras justo odio, dapibus ac facilisis in, egestas eget quam.
                </Card.Text>
                <Button variant="primary">Explore Story</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="d-flex align-items-stretch">
            <Card className="bg-light border-0 shadow-sm mb-4">
              <Card.Body>
                <Card.Title>Building a Remote Team</Card.Title>
                <Card.Text>
                  Sed posuere consectetur est at lobortis. Aenean lacinia bibendum nulla sed consectetur. Cras justo odio.
                </Card.Text>
                <Button variant="primary">Explore Story</Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="text-center">
          <Col md={6}>
            <Card className="bg-light border-0 shadow-sm mb-4">
              <Card.Body>
                <blockquote className="blockquote mb-0">
                  <p>
                    "This platform changed my life! I went from working a 9-to-5 to starting my own business, and now I have clients all over the world."
                  </p>
                  <footer className="blockquote-footer">
                    Jane Doe, <cite title="Source Title">Freelancer to CEO</cite>
                  </footer>
                </blockquote>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="bg-light border-0 shadow-sm mb-4">
              <Card.Body>
                <blockquote className="blockquote mb-0">
                  <p>
                    "We transformed our local business into a global brand thanks to the resources and connections we found here."
                  </p>
                  <footer className="blockquote-footer">
                    John Smith, <cite title="Source Title">Small Business Owner</cite>
                  </footer>
                </blockquote>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="text-center mt-5">
          <Col>
            <h2>Ready to Write Your Own Success Story?</h2>
            <Button variant="warning" size="lg">
              Get Started Today
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}
