// src/utils/constants.js

export const AUTH_TYPES = {
  USER: 'user',
  COMPANY: 'company',
  PUBLIC: 'public'
};

export const ERROR_MESSAGES = {
  COMPANY_AUTH_REQUIRED: 'Company authentication required',
  USER_AUTH_REQUIRED: 'User authentication required',
  INVALID_CREDENTIALS: 'Invalid credentials',
  SERVER_ERROR: 'Server error occurred'
};


export const jobTypes = [
  { value: 'full-time', label: 'Full Time' },
  { value: 'part-time', label: 'Part Time' },
  { value: 'contract', label: 'Contract' },
  { value: 'remote', label: 'Remote' }
];

export const jobCategories = [
  { value: 'administration', label: 'Administration' },
  { value: 'agriculture', label: 'Agriculture' },
  { value: 'architecture', label: 'Architecture' },
  { value: 'construction', label: 'Construction' },
  { value: 'customer_service', label: 'Customer Service/Support' },
  { value: 'design', label: 'Design' },
  { value: 'education', label: 'Education/Training/Culture' },
  { value: 'electronics', label: 'Electronics' },
  { value: 'engineering', label: 'Engineering/Mechanics' },
  { value: 'energy', label: 'Energy' },
  { value: 'export', label: 'Export' },
  { value: 'finance', label: 'Finance/Accounting/Banking' },
  { value: 'food_production', label: 'Food Production' },
  { value: 'government', label: 'Government/Public Administration' },
  { value: 'hr', label: 'Human Resources Management' },
  { value: 'it', label: 'Information Technology' },
  { value: 'insurance', label: 'Insurance' },
  { value: 'law', label: 'Law' },
  { value: 'leadership', label: 'Leadership/Quality Management' },
  { value: 'manufacturing', label: 'Manufacturing' },
  { value: 'marketing', label: 'Marketing/Advertising' },
  { value: 'media', label: 'Media/Communication' },
  { value: 'medicine', label: 'Medicine/Pharmacy' },
  { value: 'procurement', label: 'Procurement/Supply' },
  { value: 'real_estate', label: 'Real Estate' },
  { value: 'sales', label: 'Sales Management' },
  { value: 'security', label: 'Security' },
  { value: 'consulting', label: 'Trade - Consulting' },
  { value: 'driving', label: 'Transportation Driving' },
  { value: 'logistics', label: 'Transportation/Logistics Management' },
  { value: 'warehousing', label: 'Warehousing' }
];

export const countries = [
  {
    value: 'albania',
    label: 'Albania',
    cities: ['Tirana', 'Durrës', 'Vlorë', 'Elbasan', 'Shkodër', 'Other']
  },
  {
    value: 'austria',
    label: 'Austria',
    cities: ['Vienna', 'Graz', 'Linz', 'Salzburg', 'Innsbruck', 'Other']
  },
  {
    value: 'belgium',
    label: 'Belgium',
    cities: ['Brussels', 'Antwerp', 'Ghent', 'Charleroi', 'Liège', 'Other']
  },
  {
    value: 'bulgaria',
    label: 'Bulgaria',
    cities: ['Sofia', 'Plovdiv', 'Varna', 'Burgas', 'Ruse', 'Other']
  },
  {
    value: 'croatia',
    label: 'Croatia',
    cities: ['Zagreb', 'Split', 'Rijeka', 'Osijek', 'Zadar', 'Other']
  },
  {
    value: 'cyprus',
    label: 'Cyprus',
    cities: ['Nicosia', 'Limassol', 'Larnaca', 'Paphos', 'Famagusta', 'Other']
  },
  {
    value: 'czech_republic',
    label: 'Czech Republic',
    cities: ['Prague', 'Brno', 'Ostrava', 'Pilsen', 'Liberec', 'Other']
  },
  {
    value: 'denmark',
    label: 'Denmark',
    cities: ['Copenhagen', 'Aarhus', 'Odense', 'Aalborg', 'Frederiksberg', 'Other']
  },
  {
    value: 'estonia',
    label: 'Estonia',
    cities: ['Tallinn', 'Tartu', 'Narva', 'Pärnu', 'Kohtla-Järve', 'Other']
  },
  {
    value: 'finland',
    label: 'Finland',
    cities: ['Helsinki', 'Espoo', 'Tampere', 'Vantaa', 'Oulu', 'Other']
  },
  {
    value: 'france',
    label: 'France',
    cities: ['Paris', 'Marseille', 'Lyon', 'Toulouse', 'Nice', 'Other']
  },
  {
    value: 'germany',
    label: 'Germany',
    cities: ['Berlin', 'Hamburg', 'Munich', 'Cologne', 'Frankfurt', 'Other']
  },
  {
    value: 'greece',
    label: 'Greece',
    cities: ['Athens', 'Thessaloniki', 'Patras', 'Heraklion', 'Larissa', 'Other']
  },
  {
    value: 'hungary',
    label: 'Hungary',
    cities: ['Budapest', 'Debrecen', 'Szeged', 'Miskolc', 'Pécs', 'Other']
  },
  {
    value: 'ireland',
    label: 'Ireland',
    cities: ['Dublin', 'Cork', 'Limerick', 'Galway', 'Waterford', 'Other']
  },
  {
    value: 'italy',
    label: 'Italy',
    cities: ['Rome', 'Milan', 'Naples', 'Turin', 'Florence', 'Other']
  },
  {
    value: 'latvia',
    label: 'Latvia',
    cities: ['Riga', 'Daugavpils', 'Liepāja', 'Jelgava', 'Jūrmala', 'Other']
  },
  {
    value: 'lithuania',
    label: 'Lithuania',
    cities: ['Vilnius', 'Kaunas', 'Klaipėda', 'Šiauliai', 'Panevėžys', 'Other']
  },
  {
    value: 'luxembourg',
    label: 'Luxembourg',
    cities: ['Luxembourg City', 'Esch-sur-Alzette', 'Dudelange', 'Other']
  },
  {
    value: 'malta',
    label: 'Malta',
    cities: ['Valletta', 'Birkirkara', 'Qormi', 'Mosta', 'Other']
  },
  {
    value: 'netherlands',
    label: 'Netherlands',
    cities: ['Amsterdam', 'Rotterdam', 'The Hague', 'Utrecht', 'Eindhoven', 'Other']
  },
  {
    value: 'norway',
    label: 'Norway',
    cities: ['Oslo', 'Bergen', 'Trondheim', 'Stavanger', 'Drammen', 'Other']
  },
  {
    value: 'poland',
    label: 'Poland',
    cities: ['Warsaw', 'Kraków', 'Łódź', 'Wrocław', 'Poznań', 'Other']
  },
  {
    value: 'portugal',
    label: 'Portugal',
    cities: ['Lisbon', 'Porto', 'Vila Nova de Gaia', 'Amadora', 'Braga', 'Other']
  },
  {
    value: 'romania',
    label: 'Romania',
    cities: ['Bucharest', 'Cluj-Napoca', 'Timișoara', 'Iași', 'Constanța', 'Other']
  },
  {
    value: 'slovakia',
    label: 'Slovakia',
    cities: ['Bratislava', 'Košice', 'Prešov', 'Žilina', 'Banská Bystrica', 'Other']
  },
  {
    value: 'slovenia',
    label: 'Slovenia',
    cities: ['Ljubljana', 'Maribor', 'Celje', 'Kranj', 'Koper', 'Other']
  },
  {
    value: 'spain',
    label: 'Spain',
    cities: ['Madrid', 'Barcelona', 'Valencia', 'Seville', 'Zaragoza', 'Other']
  },
  {
    value: 'sweden',
    label: 'Sweden',
    cities: ['Stockholm', 'Gothenburg', 'Malmö', 'Uppsala', 'Västerås', 'Other']
  },
  {
    value: 'switzerland',
    label: 'Switzerland',
    cities: ['Zürich', 'Geneva', 'Basel', 'Lausanne', 'Bern', 'Other']
  },
  {
    value: 'united_kingdom',
    label: 'United Kingdom',
    cities: ['London', 'Birmingham', 'Manchester', 'Edinburgh', 'Liverpool', 'Other']
  }
];

export const experienceLevels = [
  { value: 'entry', label: 'Entry Level' },
  { value: 'mid-level', label: 'Mid Level' },
  { value: 'senior', label: 'Senior' },
  { value: 'lead', label: 'Lead' }
];

export const defaultStatuses = [
  { value: 'draft', label: 'Draft' },
  { value: 'active', label: 'Active' },
  { value: 'closed', label: 'Closed' }
];

export const API_ENDPOINTS = {
  // Auth endpoints
  LOGIN: '/users/login',
  REGISTER: '/users/register',
  COMPANY_LOGIN: '/companies/login',
  COMPANY_REGISTER: '/companies/register',
  
  // User endpoints
  UPDATE_PROFILE: '/users/profile',
  QUESTIONNAIRE: '/users/questionnaire',
  CV: {
    GET: '/users/cv',
    UPDATE: '/users/cv/update',
    SUBMIT: '/users/cv/submit'
  },
  
  // Company endpoints
  COMPANY: {
    PROFILE: '/companies/profile',
    JOBS: {
      LIST: '/companies/jobs',
      CREATE: '/companies/jobs',
      UPDATE: '/companies/jobs',
      DELETE: '/companies/jobs'
    },
    CANDIDATES: {
      SEARCH: '/companies/candidates/search',
      MATCH: '/companies/candidates/match'
    }
  },

  // AI endpoints
  AI: {
    JOB_MATCH: '/ai/match/jobs',
    CANDIDATE_MATCH: '/ai/match/candidates'
  }
};