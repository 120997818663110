// Pages/ProfileInfoPage.js
import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

export default function ProfileInfoPage() {
  return (
    <Container className="mt-3">
      <h1>Profile Information</h1>
      <Row>
        <Col md={6}>
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Personal Details</Card.Title>
              <Card.Text>Name: John Doe</Card.Text>
              <Card.Text>Email: johndoe@example.com</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Professional Details</Card.Title>
              <Card.Text>Experience: 5 years in Software Development</Card.Text>
              <Card.Text>Skills: React, Node.js, MongoDB</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
