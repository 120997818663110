import React, { useContext, useEffect, useState } from 'react';
import { Alert, Button, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Store } from '../context/context';
import api from '../config/axios';

const ProfileReminder = () => {
  const { state } = useContext(Store);
  const { userInfo } = state;
  const [loading, setLoading] = useState(true);
  const [needsReminder, setNeedsReminder] = useState({
    cv: false,
    profile: false,
  });

  useEffect(() => {
    const checkUserData = async () => {
      if (!userInfo) {
        setLoading(false);
        return;
      }

      try {
        const { data } = await api.get('/users/cv');
        const profileResponse = await api.get('/users/profile');

        console.log('CV Response:', data);
        console.log('Profile Response:', profileResponse.data);

        setNeedsReminder({
          // Check if CV exists and is completed
          cv: !data?.cv || data.cv.status !== 'completed',
          // Check if profile questionnaire is completed
          profile:
            !profileResponse.data?.user?.employeeProfile ||
            profileResponse.data.user.employeeProfile.length === 0,
        });

        console.log('Reminder State:', {
          cv: !data?.cv || data.cv.status !== 'completed',
          profile:
            !profileResponse.data?.user?.employeeProfile ||
            profileResponse.data.user.employeeProfile.length === 0,
        });
      } catch (error) {
        console.error('Error fetching user data:', error);
        // If there's an error fetching, assume both need to be completed
        setNeedsReminder({ cv: true, profile: true });
      } finally {
        setLoading(false);
      }
    };

    checkUserData();
  }, [userInfo]);

  // Don't render if not logged in
  if (!userInfo) return null;

  // Show loading state
  if (loading) {
    return (
      <div className="text-center my-3">
        <Spinner animation="border" variant="primary" size="sm" />
      </div>
    );
  }

  // Don't render if everything is complete
  if (!needsReminder.cv && !needsReminder.profile) return null;

  return (
    <Alert variant="warning" className="mb-4">
      <Alert.Heading>
        <i className="bi bi-exclamation-triangle me-2"></i>
        Complete Your Profile
      </Alert.Heading>
      <p>
        Your profile needs to be completed to get the best job matches. Please
        complete the following:
      </p>

      <div className="d-flex flex-column gap-3">
        {needsReminder.cv && (
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <i className="bi bi-x-circle text-danger me-2"></i>
              Your CV needs to be completed
            </div>
            <Link to="/create-cv">
              <Button size="sm" variant="outline-primary">
                Complete CV
              </Button>
            </Link>
          </div>
        )}

        {needsReminder.profile && (
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <i className="bi bi-x-circle text-danger me-2"></i>
              Personal profile questions need to be answered
            </div>
            <Link to="/questionnaire">
              <Button size="sm" variant="outline-primary">
                Answer Questions
              </Button>
            </Link>
          </div>
        )}
      </div>

      <hr />
      <p className="mb-0 small text-muted">
        After profile and CV completed you will be able to apply for jobs and
        get your profile matched by AI. A complete profile significantly
        increases your chances of finding the perfect job match.
      </p>
    </Alert>
  );
};

export default ProfileReminder;
