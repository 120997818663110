// Pages/AIPage/AIPage.js
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './aiPage.css';

const AIPage = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <div className="ai-page">
      {/* Hero Section */}
      <div className="hero-section text-center position-relative overflow-hidden">
        <div className="neural-bg"></div>
        <Container className="hero-content">
          <h1 className={`display-3 fw-bold mb-4 ${isVisible ? 'fade-in' : ''}`}>
            HireIntel AI Technology
          </h1>
          <p className={`lead mb-5 ${isVisible ? 'fade-in delay-1' : ''}`}>
            Revolutionizing recruitment through intelligent matching
          </p>
          <div className="scroll-indicator">
            <div className="mouse"></div>
          </div>
        </Container>
      </div>

      {/* How It Works Section */}
      <section className="how-it-works py-5">
        <Container>
          <h2 className="text-center mb-5">How Our AI Works</h2>
          <Row className="g-4">
            <Col md={4}>
              <Card className="feature-card h-100">
                <div className="icon-wrapper">
                  <i className="bi bi-brain"></i>
                </div>
                <Card.Body>
                  <Card.Title>Deep Learning Analysis</Card.Title>
                  <Card.Text>
                    Our AI processes millions of successful job matches to understand 
                    patterns in career progression and job satisfaction.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="feature-card h-100">
                <div className="icon-wrapper">
                  <i className="bi bi-puzzle"></i>
                </div>
                <Card.Body>
                  <Card.Title>Perfect Match Algorithm</Card.Title>
                  <Card.Text>
                    Beyond keywords, we analyze context, culture fit, and career trajectory 
                    to find the perfect match.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="feature-card h-100">
                <div className="icon-wrapper">
                  <i className="bi bi-graph-up"></i>
                </div>
                <Card.Body>
                  <Card.Title>Continuous Learning</Card.Title>
                  <Card.Text>
                    Our system evolves with each successful match, continuously improving 
                    accuracy and predictions.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Interactive Process Flow */}
      <section className="process-flow py-5 bg-light">
        <Container>
          <h2 className="text-center mb-5">The Matching Process</h2>
          <div className="timeline">
            <div className="timeline-item">
              <div className="timeline-content">
                <h4>Data Collection</h4>
                <p>CV analysis and skill assessment</p>
              </div>
            </div>
            <div className="timeline-item">
              <div className="timeline-content">
                <h4>Profile Enhancement</h4>
                <p>AI-powered suggestions for profile optimization</p>
              </div>
            </div>
            <div className="timeline-item">
              <div className="timeline-content">
                <h4>Intelligent Matching</h4>
                <p>Multi-dimensional compatibility analysis</p>
              </div>
            </div>
            <div className="timeline-item">
              <div className="timeline-content">
                <h4>Connection</h4>
                <p>Facilitating perfect employer-employee matches</p>
              </div>
            </div>
          </div>
        </Container>
      </section>

      {/* Statistics Section */}
      <section className="statistics py-5">
        <Container>
          <Row className="text-center g-4">
            <Col md={3}>
              <div className="stat-card">
                <h3 className="counter">95%</h3>
                <p>Match Accuracy</p>
              </div>
            </Col>
            <Col md={3}>
              <div className="stat-card">
                <h3 className="counter">2x</h3>
                <p>Faster Hiring</p>
              </div>
            </Col>
            <Col md={3}>
              <div className="stat-card">
                <h3 className="counter">80%</h3>
                <p>Better Retention</p>
              </div>
            </Col>
            <Col md={3}>
              <div className="stat-card">
                <h3 className="counter">1M+</h3>
                <p>Successful Matches</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Technologies Used */}
      <section className="technologies py-5">
        <Container>
          <h2 className="text-center mb-5">Powered By Cutting-Edge Tech</h2>
          <div className="tech-grid">
            <div className="tech-item">
              <div className="tech-icon">
                <i className="bi bi-cpu"></i>
              </div>
              <h5>Machine Learning</h5>
            </div>
            <div className="tech-item">
              <div className="tech-icon">
                <i className="bi bi-chat-dots"></i>
              </div>
              <h5>NLP Processing</h5>
            </div>
            <div className="tech-item">
              <div className="tech-icon">
                <i className="bi bi-graph-up-arrow"></i>
              </div>
              <h5>Predictive Analytics</h5>
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default AIPage;