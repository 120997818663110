import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Card,
  Badge,
  Button,
  Form,
  Spinner,
  Alert,
  Nav,
  Tab,
} from 'react-bootstrap';
import api from '../../config/axios';
import { toast } from 'react-toastify';


const ApplicationDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [application, setApplication] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newStatus, setNewStatus] = useState('');
  const [note, setNote] = useState('');
  const [activeTab, setActiveTab] = useState('overview');
  // Add these near the top with other state declarations
  const [evaluation, setEvaluation] = useState(null);
  const [evaluationLoading, setEvaluationLoading] = useState(false);
  const [evaluationError, setEvaluationError] = useState(null);

  // Add this helper function
  const getRoleFitBadgeVariant = (score) => {
    if (score >= 80) return 'success';
    if (score >= 60) return 'warning';
    return 'danger';
  };

  // Add this handler function
  const handleRequestEvaluation = async () => {
    try {
      setEvaluationLoading(true);
      setEvaluationError(null);

      // Request evaluation
      const evaluationResponse = await api.post(`/applications/${id}/evaluate`);
      console.log('Evaluation response:', evaluationResponse.data);

      // Refresh application data to get updated evaluations
      const applicationResponse = await api.get(`/applications/${id}`);
      console.log('Updated application:', applicationResponse.data);

      // Update the evaluation state
      if (evaluationResponse.data) {
        setEvaluation(evaluationResponse.data);
        toast.success('Evaluation completed successfully');
      }

      // Update application data
      if (applicationResponse.data) {
        setApplication(applicationResponse.data);
      }
    } catch (error) {
      console.error('Evaluation error:', error);
      setEvaluationError(
        error.response?.data?.message || 'Failed to generate evaluation'
      );
      toast.error(
        error.response?.data?.message || 'Failed to generate evaluation'
      );
    } finally {
      setEvaluationLoading(false);
    }
  };

  const formatQuestionType = (type) => {
    if (!type) return '';
    return type
      .split(/(?=[A-Z])/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  useEffect(() => {
    fetchApplicationDetails();
  }, [id]);

  const fetchApplicationDetails = async () => {
    try {
      setLoading(true);
      setError(null);
      const { data } = await api.get(`/applications/${id}`);
      if (!data) {
        throw new Error('Application not found');
      }
      setApplication(data);
      setNewStatus(data.status);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        'Error fetching application details';
      setError(errorMessage);
      toast.error(errorMessage);
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const updateStatus = async () => {
    try {
      await api.put(`/applications/${id}/status`, {
        status: newStatus,
        note: note.trim() ? note : undefined,
      });
      toast.success('Application status updated');
      fetchApplicationDetails();
      setNote('');
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || 'Error updating application status';
      toast.error(errorMessage);
      console.error('Update status error:', error);
    }
  };

  const renderSkillBadges = (skills, type) => {
    const getBadgeVariant = (level) => {
      switch (level) {
        case 'Expert':
          return 'danger';
        case 'Advanced':
          return 'success';
        case 'Intermediate':
          return 'primary';
        case 'Basic':
          return 'info';
        default:
          return 'secondary';
      }
    };

    return skills?.map((skill, index) => (
      <Badge
        key={index}
        bg={getBadgeVariant(skill.level)}
        className="me-2 mb-2 py-2 px-3"
      >
        {skill.name} {type !== 'language' && `(${skill.level})`}
      </Badge>
    ));
  };

  const renderTimeline = (items, renderContent) => {
    return items?.map((item, index) => (
      <div
        key={index}
        className={`position-relative mb-4 ${
          index === items.length - 1 ? '' : 'timeline-item'
        }`}
      >
        <div className="timeline-content bg-light p-3 rounded">
          {renderContent(item)}
        </div>
      </div>
    ));
  };

  if (loading) {
    return (
      <Container className="text-center py-5">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="py-5">
        <Alert variant="danger">{error}</Alert>
        <Button
          variant="outline-primary"
          className="mt-3"
          onClick={() => navigate(-1)}
        >
          Go Back
        </Button>
      </Container>
    );
  }

  return (
    <Container className="py-4">
      <Button
        variant="outline-primary"
        className="mb-4"
        onClick={() => navigate('/applications')}
      >
        <i className="bi bi-arrow-left me-2"></i>
        Back to Applications
      </Button>

      <Row>
        <Col lg={8}>
          <Tab.Container defaultActiveKey="overview">
            <Card className="mb-4">
              <Card.Header className="bg-primary text-white">
                <div className="d-flex justify-content-between align-items-center">
                  <h4 className="mb-0">Application Details</h4>
                  <Badge
                    bg={
                      application.status === 'accepted'
                        ? 'success'
                        : application.status === 'rejected'
                        ? 'danger'
                        : application.status === 'shortlisted'
                        ? 'warning'
                        : 'secondary'
                    }
                    className="fs-6"
                  >
                    {application.status.toUpperCase()}
                  </Badge>
                </div>
              </Card.Header>
              <Card.Body>
                <Nav variant="tabs" className="mb-3">
                  <Nav.Item>
                    <Nav.Link eventKey="overview">Overview</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="cv">Full CV</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="assessment">
                      Personal Assessment
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="evaluation">AI Evaluation</Nav.Link>
                  </Nav.Item>
                </Nav>

                <Tab.Content>
                  <Tab.Pane eventKey="overview">
                    <div className="mb-4">
                      <h5>Position Details</h5>
                      <Card className="bg-light">
                        <Card.Body>
                          <h6 className="mb-2">{application.jobId.title}</h6>
                          <p className="text-muted mb-0">
                            Applied on:{' '}
                            {new Date(
                              application.createdAt
                            ).toLocaleDateString()}
                          </p>
                        </Card.Body>
                      </Card>
                    </div>

                    <div className="mb-4">
                      <h5>Cover Letter</h5>
                      <Card className="bg-light">
                        <Card.Body>
                          <p
                            className="mb-0"
                            style={{ whiteSpace: 'pre-line' }}
                          >
                            {application.coverLetter}
                          </p>
                        </Card.Body>
                      </Card>
                    </div>

                    <div className="mb-4">
                      <h5>Key Skills</h5>
                      <div>{renderSkillBadges(application.cv.skills)}</div>
                    </div>

                    <div className="mb-4">
                      <h5>Languages</h5>
                      <div>
                        {renderSkillBadges(
                          application.cv.languages,
                          'language'
                        )}
                      </div>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="cv">
                    <div className="mb-4">
                      <h5 className="border-bottom pb-2">
                        Personal Information
                      </h5>
                      <Row>
                        <Col md={6}>
                          <p>
                            <strong>Name:</strong> {application.userId.name}
                          </p>
                          <p>
                            <strong>Email:</strong> {application.userId.email}
                          </p>
                          {application.cv.personalInfo?.phoneNumber && (
                            <p>
                              <strong>Phone:</strong>{' '}
                              {application.cv.personalInfo.phoneNumber}
                            </p>
                          )}
                        </Col>
                        <Col md={6}>
                          {application.cv.personalInfo?.address && (
                            <p>
                              <strong>Location:</strong>{' '}
                              {application.cv.personalInfo.address}
                            </p>
                          )}
                          {application.cv.personalInfo?.linkedIn && (
                            <p>
                              <strong>LinkedIn:</strong>{' '}
                              <a
                                href={application.cv.personalInfo.linkedIn}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Profile
                              </a>
                            </p>
                          )}
                        </Col>
                      </Row>
                    </div>

                    <div className="mb-4">
                      <h5 className="border-bottom pb-2">
                        Professional Experience
                      </h5>
                      {renderTimeline(application.cv.experience, (exp) => (
                        <>
                          <h6>{exp.position}</h6>
                          <div className="text-muted mb-2">
                            {exp.company} • {exp.location}
                            <br />
                            {new Date(exp.startDate).toLocaleDateString()} -
                            {exp.current
                              ? 'Present'
                              : new Date(exp.endDate).toLocaleDateString()}
                          </div>
                          <p
                            className="mb-0"
                            style={{ whiteSpace: 'pre-line' }}
                          >
                            {exp.description}
                          </p>
                        </>
                      ))}
                    </div>

                    <div className="mb-4">
                      <h5 className="border-bottom pb-2">Education</h5>
                      {renderTimeline(application.cv.education, (edu) => (
                        <>
                          <h6>
                            {edu.degree} in {edu.fieldOfStudy}
                          </h6>
                          <div className="text-muted mb-2">
                            {edu.institution}
                            <br />
                            {new Date(edu.startDate).toLocaleDateString()} -
                            {edu.current
                              ? 'Present'
                              : new Date(edu.endDate).toLocaleDateString()}
                          </div>
                          {edu.description && (
                            <p
                              className="mb-0"
                              style={{ whiteSpace: 'pre-line' }}
                            >
                              {edu.description}
                            </p>
                          )}
                        </>
                      ))}
                    </div>

                    {application.cv.projects?.length > 0 && (
                      <div className="mb-4">
                        <h5 className="border-bottom pb-2">Projects</h5>
                        {application.cv.projects.map((project, index) => (
                          <Card key={index} className="mb-3">
                            <Card.Body>
                              <h6>{project.title}</h6>
                              <div className="text-muted mb-2">
                                {new Date(
                                  project.startDate
                                ).toLocaleDateString()}{' '}
                                -
                                {new Date(project.endDate).toLocaleDateString()}
                              </div>
                              <p>{project.description}</p>
                              {project.technologies?.length > 0 && (
                                <div>
                                  <strong>Technologies:</strong>{' '}
                                  {project.technologies.join(', ')}
                                </div>
                              )}
                              {project.url && (
                                <a
                                  href={project.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="btn btn-sm btn-outline-primary mt-2"
                                >
                                  View Project
                                </a>
                              )}
                            </Card.Body>
                          </Card>
                        ))}
                      </div>
                    )}

                    <div className="mb-4">
                      <h5 className="border-bottom pb-2">Skills</h5>
                      <div className="mb-3">
                        <h6 className="text-muted">Technical Skills</h6>
                        {renderSkillBadges(application.cv.skills)}
                      </div>
                      <div>
                        <h6 className="text-muted">Languages</h6>
                        {renderSkillBadges(
                          application.cv.languages,
                          'language'
                        )}
                      </div>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="assessment">
                    {application?.userId?.employeeProfile?.map(
                      (profile, index) => (
                        <div key={index} className="mb-4">
                          <h6 className="text-primary">
                            {formatQuestionType(profile.questionType)}
                          </h6>
                          <p className="text-muted mb-2">
                            <small>{profile.questionLabel}</small>
                          </p>
                          <div className="bg-light p-3 rounded">
                            {profile.answer}
                          </div>
                        </div>
                      )
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="evaluation">
                    {evaluationError && (
                      <Alert variant="danger" className="mb-4">
                        {evaluationError}
                      </Alert>
                    )}

                    {!evaluation ? (
                      <div className="text-center py-4">
                        <p className="mb-4">No evaluation available yet.</p>
                        <Button
                          variant="primary"
                          onClick={handleRequestEvaluation}
                          disabled={evaluationLoading}
                        >
                          {evaluationLoading ? (
                            <>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="me-2"
                              />
                              Generating Evaluation...
                            </>
                          ) : (
                            'Request AI Evaluation'
                          )}
                        </Button>
                      </div>
                    ) : (
                      <div className="evaluation-results">
                        {/* Psychological Evaluation */}
                        <Card className="mb-4">
                          <Card.Header>
                            <h5 className="mb-0">Psychological Profile</h5>
                          </Card.Header>
                          <Card.Body>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: evaluation.psychologicalEvaluation,
                              }}
                            />
                          </Card.Body>
                        </Card>

                        {/* Role Fit Evaluation */}
                        <Card className="mb-4">
                          <Card.Header className="d-flex justify-content-between align-items-center">
                            <h5 className="mb-0">Role Fit Analysis</h5>
                            <Badge
                              bg={getRoleFitBadgeVariant(
                                evaluation.roleFit.score
                              )}
                            >
                              {evaluation.roleFit.score}% Match
                            </Badge>
                          </Card.Header>
                          <Card.Body>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: evaluation.roleFit.analysis,
                              }}
                            />
                          </Card.Body>
                        </Card>

                        {/* Consistency Check */}
                        <Card className="mb-4">
                          <Card.Header>
                            <h5 className="mb-0">Consistency Analysis</h5>
                          </Card.Header>
                          <Card.Body>
                            <Alert
                              variant={
                                evaluation.consistency.concerns
                                  ? 'warning'
                                  : 'success'
                              }
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: evaluation.consistency.analysis,
                                }}
                              />
                            </Alert>
                            {evaluation.consistency.recommendations && (
                              <div className="mt-3">
                                <h6>Recommendations:</h6>
                                <ul className="mb-0">
                                  {evaluation.consistency.recommendations.map(
                                    (rec, index) => (
                                      <li key={index}>{rec}</li>
                                    )
                                  )}
                                </ul>
                              </div>
                            )}
                          </Card.Body>
                        </Card>

                        {/* Overall Recommendation */}
                        <Card>
                          <Card.Header>
                            <h5 className="mb-0">Overall Recommendation</h5>
                          </Card.Header>
                          <Card.Body>
                            <div
                              className="mb-3"
                              dangerouslySetInnerHTML={{
                                __html: evaluation.recommendation.summary,
                              }}
                            />
                            <Row>
                              <Col md={6}>
                                <h6>Strengths:</h6>
                                <ul>
                                  {evaluation.recommendation.strengths.map(
                                    (strength, index) => (
                                      <li key={index}>{strength}</li>
                                    )
                                  )}
                                </ul>
                              </Col>
                              <Col md={6}>
                                <h6>Areas to Explore:</h6>
                                <ul>
                                  {evaluation.recommendation.areasToExplore.map(
                                    (area, index) => (
                                      <li key={index}>{area}</li>
                                    )
                                  )}
                                </ul>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </div>
                    )}
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Card>
          </Tab.Container>
        </Col>

        <Col lg={4}>
          {/* Status Update Card */}
          <Card className="mb-4">
            <Card.Header className="bg-light">
              <h5 className="mb-0">Update Status</h5>
            </Card.Header>
            <Card.Body>
              <Form.Group className="mb-3">
                <Form.Label>Change Status</Form.Label>
                <Form.Select
                  value={newStatus}
                  onChange={(e) => setNewStatus(e.target.value)}
                >
                  <option value="pending">Pending</option>
                  <option value="reviewed">Reviewed</option>
                  <option value="shortlisted">Shortlisted</option>
                  <option value="rejected">Rejected</option>
                  <option value="accepted">Accepted</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Add Note</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  placeholder="Add a note about this update (optional)"
                />
              </Form.Group>

              <Button
                variant="primary"
                className="w-100"
                onClick={updateStatus}
                disabled={newStatus === application.status && !note}
              >
                Update Status
              </Button>
            </Card.Body>
          </Card>

          {/* Notes History Card */}
          <Card>
            <Card.Header className="bg-light">
              <h5 className="mb-0">Notes History</h5>
            </Card.Header>
            <Card.Body>
              {application.notes && application.notes.length > 0 ? (
                application.notes.map((note, index) => (
                  <div key={index} className="mb-3 pb-3 border-bottom">
                    <p className="mb-1">{note.content}</p>
                    <small className="text-muted">
                      {new Date(note.createdAt).toLocaleString()}
                    </small>
                  </div>
                ))
              ) : (
                <p className="text-muted mb-0">No notes yet</p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ApplicationDetailsPage;
