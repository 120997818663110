// Components/Dashboard/NavBarCompany.js
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Store } from '../../context/context';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function NavBarCompany() {
  const navigate = useNavigate();
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { companyInfo } = state;


  const signoutHandler = () => {
    ctxDispatch({ type: 'COMPANY_SIGNOUT' });
    localStorage.removeItem('companyInfo');
    navigate('/');
    window.location.reload();
  };

  return (
    <div className="d-flex flex-column site-container">
      <ToastContainer position="bottom-center" limit={1} />
      <header>
        <Navbar bg="dark" variant="dark">
          <Container>
            <LinkContainer to="/company-dashboard">
              <Navbar.Brand>Dashboard</Navbar.Brand>
            </LinkContainer>
            <LinkContainer to="/job-postings">
              <Navbar.Brand>My job Postings</Navbar.Brand>
            </LinkContainer>
            <LinkContainer to="/candidate-search">
              <Navbar.Brand>Manual search</Navbar.Brand>
            </LinkContainer>
            <LinkContainer to="/ai/match/candidates">
              <Navbar.Brand>AI search
              </Navbar.Brand>
            </LinkContainer>
            <LinkContainer to="/applications/company">
              <Navbar.Brand>Received applications
              </Navbar.Brand>
            </LinkContainer>
            <LinkContainer to="/analytics">
              <Navbar.Brand>Analytics
              </Navbar.Brand>
            </LinkContainer>

            

            <Nav className="me-auto">
              {companyInfo ? (
                <NavDropdown title={companyInfo.name} id="company-nav-dropdown">
                  <LinkContainer to="/company-profile">
                    <NavDropdown.Item>Company Profile</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/company-settings">
                    <NavDropdown.Item>Settings</NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Divider />
                  <Link
                    className="dropdown-item"
                    to="#"
                    onClick={signoutHandler}
                  >
                    Logout
                  </Link>
                </NavDropdown>
              ) : (
                <Link className="nav-link" to="/login">
                  Login
                </Link>
              )}
            </Nav>
          </Container>
        </Navbar>
      </header>
    </div>
  );
}

export default NavBarCompany;