// Pages/AIMatchJobsPage.js
import React, { useState, useContext } from 'react';
import {
  Container,
  Form,
  Button,
  Card,
  Alert,
  Spinner,
  Badge,
  Row,
  Col,
} from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { Store } from '../../context/context';
import { getAIJobMatches } from '../../services/aiService';
import { useNavigate } from 'react-router-dom';
import ProfileReminder from '../../components/profileReminder';

export default function AIMatchJobsPage() {
  const { state } = useContext(Store);
  const { userInfo } = state;
  const navigate = useNavigate();

  const [request, setRequest] = useState('');
  const [matches, setMatches] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  React.useEffect(() => {
    if (!userInfo) {
      navigate('/login');
    }
  }, [userInfo, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const result = await getAIJobMatches(request);
      console.log('AI Match Results:', result);
      setMatches(result);
    } catch (err) {
      setError(err.response?.data?.message || 'Error getting job matches');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="py-4">
      <ProfileReminder />
      <Helmet>
        <title>AI Job Matching</title>
      </Helmet>

      <h1 className="mb-4">AI Job Matching</h1>

      <Card className="mb-4">
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>What kind of job are you looking for?</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                value={request}
                onChange={(e) => setRequest(e.target.value)}
                placeholder="Describe your ideal job, including any specific requirements, preferred technologies, work environment, etc."
                required
              />
            </Form.Group>
            <Button type="submit" disabled={loading || !request.trim()}>
              {loading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="me-2"
                  />
                  Finding matches...
                </>
              ) : (
                'Find Matching Jobs'
              )}
            </Button>
          </Form>
        </Card.Body>
      </Card>

      {error && <Alert variant="danger">{error}</Alert>}

      {matches && matches.matches && matches.matches.map((match, index) => (
  <Card key={index} className="mb-4">
    <Card.Header className="bg-light">
      <div className="d-flex justify-content-between align-items-center">
        <h5 className="mb-0">{match.title}</h5>
        <Badge 
          bg={match.matchScore >= 80 ? 'success' : 
              match.matchScore >= 60 ? 'warning' : 'danger'}
          className="fs-6"
        >
          Match Score: {match.matchScore}%
        </Badge>
      </div>
    </Card.Header>
    
    <Card.Body>
      <Row>
        <Col lg={6}>
          {/* Skills Match Card */}
          <Card className="mb-3">
            <Card.Body>
              <h6 className="text-primary">Skills Match</h6>
              <div className="mb-2">
                <strong>Matching Skills:</strong>
                <div className="d-flex flex-wrap gap-2 mt-1">
                  {match.skillsMatch.map((skill, idx) => (
                    <Badge key={idx} bg="success">{skill}</Badge>
                  ))}
                </div>
              </div>
            </Card.Body>
          </Card>

          {/* Job Details Card */}
          <Card className="mb-3">
            <Card.Body>
              <h6 className="text-primary">Job Details</h6>
              <div className="mb-2">
                <p><strong>Type:</strong> {match.type}</p>
                <p><strong>Location:</strong> {match.jobDetails?.location}</p>
                {match.jobDetails?.company && (
                  <p><strong>Company:</strong> {match.jobDetails.company.name}</p>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={6}>
          {/* Match Analysis Card */}
          <Card className="mb-3">
            <Card.Body>
              <h6 className="text-primary">Match Analysis</h6>
              <div className="mb-2">
                <strong>Experience Match:</strong>
                <p>{match.experienceMatch}</p>
              </div>
              <div className="mb-2">
                <strong>Why This Match?</strong>
                <ul className="mt-1 mb-0">
                  {match.reasons.map((reason, idx) => (
                    <li key={idx}>{reason}</li>
                  ))}
                </ul>
              </div>
            </Card.Body>
          </Card>

          {/* Requirements Card */}
          {match.jobDetails?.requirements && (
            <Card className="mb-3">
              <Card.Body>
                <h6 className="text-primary">Job Requirements</h6>
                <p>{match.jobDetails.requirements}</p>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>

      {/* Description Card
      {match.jobDetails?.description && (
        <Card className="mt-3">
          <Card.Body>
            <h6 className="text-primary">Job Description</h6>
            <p>{match.jobDetails.description}</p>
          </Card.Body>
        </Card>
      )} */}

      {/* Action Buttons */}
      <div className="mt-4 d-flex justify-content-end gap-2">
        <Button 
          variant="primary"
          onClick={() => {
            navigate('/applications/new', { 
              state: { 
                jobId: match.jobId,
                useAI: true 
              }
            });
          }}
        >
          Apply with AI
        </Button>

        <Button 
          variant="outline-primary"
          onClick={() => window.open(`/jobs/${match.jobId}`, '_blank')}
        >
          View Full Job Details
        </Button>
      </div>
    </Card.Body>
  </Card>
))}
    </Container>
  );
}
