// Pages/MainPage.js
import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './mainPage.css';

export default function MainPage() {
  return (
    <div className="main-page">
      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-overlay"></div>
        <Container>
          <div className="hero-content-wrapper">
            <Row className="align-items-center justify-content-center w-100">
              <Col lg={6} className="text-light text-center">
                <div className="hero-content">
                  <h1 className="display-3 fw-bold mb-4 animate-up">
                    Find Your Perfect{' '}
                    <span className="gradient-text">Match</span>
                  </h1>
                  <p className="lead mb-4 animate-up delay-1">
                    HireIntel uses advanced AI to connect talented professionals
                    with their ideal employers. Experience the future of
                    recruitment.
                  </p>
                  <div className="d-flex justify-content-center gap-3 animate-up delay-2">
                    <Link to="/register-employee">
                      <Button
                        className="glow-button"
                        variant="primary"
                        size="lg"
                      >
                        Find a Job
                      </Button>
                    </Link>
                    <Link to="/register-company">
                      <Button
                        className="glow-button"
                        variant="outline-light"
                        size="lg"
                      >
                        Hire Talent
                      </Button>
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>

      {/* Features Section */}
      <section className="features-section py-5">
        <Container>
          <h2 className="text-center mb-5">Why Choose HireIntel?</h2>
          <Row className="g-4">
            <Col md={4}>
              <div className="feature-card">
                <div className="feature-icon">
                  <i className="bi bi-yin-yang"></i>
                </div>
                <h3>AI-Powered Matching</h3>
                <p>
                  Our intelligent system analyzes multiple factors to ensure
                  perfect job matches
                </p>
              </div>
            </Col>
            <Col md={4}>
              <div className="feature-card">
                <div className="feature-icon">
                  <i className="bi bi-clock"></i>
                </div>
                <h3>Time-Saving</h3>
                <p>
                  Reduce hiring time by 50% with our automated matching system
                </p>
              </div>
            </Col>
            <Col md={4}>
              <div className="feature-card">
                <div className="feature-icon">
                  <i className="bi bi-graph-up"></i>
                </div>
                <h3>Better Outcomes</h3>
                <p>95% satisfaction rate with matched positions</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* How It Works Section */}
      <section className="how-it-works py-5 bg-light">
        <Container>
          <h2 className="text-center mb-5">How It Works</h2>
          <Row className="g-4">
            <Col lg={3} md={6}>
              <div className="step-card">
                <div className="step-number">1</div>
                <h4>Create Profile</h4>
                <p>Set up your professional profile or company details</p>
              </div>
            </Col>
            <Col lg={3} md={6}>
              <div className="step-card">
                <div className="step-number">2</div>
                <h4>AI Analysis</h4>
                <p>Our AI analyzes your requirements and preferences</p>
              </div>
            </Col>
            <Col lg={3} md={6}>
              <div className="step-card">
                <div className="step-number">3</div>
                <h4>Get Matches</h4>
                <p>Receive highly compatible job or candidate matches</p>
              </div>
            </Col>
            <Col lg={3} md={6}>
              <div className="step-card">
                <div className="step-number">4</div>
                <h4>Connect</h4>
                <p>Connect with your matches and start conversations</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Stats Section */}
      <section className="stats-section py-5">
        <Container>
          <Row className="text-center g-4">
            <Col md={3}>
              <div className="stat-item">
                <h3 className="stat-number">10K+</h3>
                <p>Active Users</p>
              </div>
            </Col>
            <Col md={3}>
              <div className="stat-item">
                <h3 className="stat-number">95%</h3>
                <p>Success Rate</p>
              </div>
            </Col>
            <Col md={3}>
              <div className="stat-item">
                <h3 className="stat-number">48h</h3>
                <p>Average Match Time</p>
              </div>
            </Col>
            <Col md={3}>
              <div className="stat-item">
                <h3 className="stat-number">5K+</h3>
                <p>Companies</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* CTA Section */}
      <section className="cta-section py-5 text-center text-light">
        <Container>
          <h2 className="mb-4">Ready to Find Your Perfect Match?</h2>
          <p className="lead mb-4">
            Join thousands of successful professionals and companies
          </p>
          <div className="d-flex justify-content-center gap-3">
            <Link to="/register-employee">
              <Button variant="light" size="lg">
                Job Seekers
              </Button>
            </Link>
            <Link to="/register-company">
              <Button variant="outline-light" size="lg">
                Employers
              </Button>
            </Link>
          </div>
        </Container>
      </section>
    </div>
  );
}
