import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import api from '../../config/axios';
import { toast } from 'react-toastify';
import PersonalInfoForm from './personalInfoForm';
import ExperienceForm from './experienceForm';
import EducationForm from './educationForm';
import SkillsForm from './skillsForm';
import ProjectsForm from './projectsForm';
import ReviewForm from './reviewForm';
import CVStepper from './components/cvStepper';

const STEPS = {
  PERSONAL_INFO: 0,
  EXPERIENCE: 1,
  EDUCATION: 2,
  SKILLS: 3,
  PROJECTS: 4,
  REVIEW: 5
};

const initialState = {
  personalInfo: {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    linkedIn: ''
  },
  experience: [],
  education: [],
  skills: [],
  languages: [],
  projects: []
};

export default function CVModalManager({ show, onHide }) {
  const [currentStep, setCurrentStep] = useState(STEPS.PERSONAL_INFO);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(initialState);

  useEffect(() => {
    if (show) {
      loadCV();
    }
  }, [show]);

  const loadCV = async () => {
    try {
      setIsLoading(true);
      const { data } = await api.get('/users/cv');
      if (data.cv) {
        setFormData({
          ...initialState,
          ...data.cv,
          skills: data.cv.skills || [],
          languages: data.cv.languages || []
        });
      }
    } catch (error) {
      toast.error('Error loading CV data');
    } finally {
      setIsLoading(false);
    }
  };

  const updateSection = (section, data) => {
    setFormData(prev => ({
      ...prev,
      [section]: data
    }));
  };

  const handleSubmit = async () => {
    try {
      await api.post('/users/cv/submit', formData);
      toast.success('CV completed successfully!');
      onHide();
    } catch (error) {
      toast.error('Error submitting CV');
    }
  };

  const renderStep = () => {
    const commonProps = {
      data: formData,
      onUpdate: updateSection
    };

    switch (currentStep) {
      case STEPS.PERSONAL_INFO:
        return <PersonalInfoForm {...commonProps} />;
      case STEPS.EXPERIENCE:
        return <ExperienceForm {...commonProps} />;
      case STEPS.EDUCATION:
        return <EducationForm {...commonProps} />;
      case STEPS.SKILLS:
        return <SkillsForm {...commonProps} />;
      case STEPS.PROJECTS:
        return <ProjectsForm {...commonProps} />;
      case STEPS.REVIEW:
        return <ReviewForm data={formData} />;
      default:
        return null;
    }
  };

  const canProceed = () => {
    switch (currentStep) {
      case STEPS.PERSONAL_INFO:
        return formData.personalInfo?.firstName && formData.personalInfo?.lastName;
      case STEPS.EXPERIENCE:
        return formData.experience?.length > 0;
      case STEPS.EDUCATION:
        return formData.education?.length > 0;
      case STEPS.SKILLS:
        return formData.skills?.length > 0;
      case STEPS.PROJECTS:
        return true; // Projects are optional
      case STEPS.REVIEW:
        return true; // Can always proceed from review
      default:
        return false;
    }
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Create Your CV</Modal.Title>
      </Modal.Header>
      
      <Modal.Body>
        <CVStepper 
          currentStep={currentStep} 
          setCurrentStep={setCurrentStep}
          cvData={formData}
        />

        {isLoading ? (
          <div className="text-center p-4">Loading...</div>
        ) : (
          renderStep()
        )}
      </Modal.Body>
      
      <Modal.Footer>
        {currentStep > 0 && (
          <Button 
            variant="secondary" 
            onClick={() => setCurrentStep(prev => prev - 1)}
          >
            Previous
          </Button>
        )}
        
        {currentStep < STEPS.REVIEW ? (
          <Button 
            variant="primary"
            onClick={() => setCurrentStep(prev => prev + 1)}
            disabled={!canProceed()}
          >
            Next
          </Button>
        ) : (
          <Button 
            variant="success"
            onClick={handleSubmit}
          >
            Complete CV
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}