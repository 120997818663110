// src/Components/CV/Forms/SkillsForm.js
import React, { useState } from 'react';
import { Form, Button, Badge, Row, Col } from 'react-bootstrap';

const skillLevels = ['Beginner', 'Intermediate', 'Advanced', 'Expert'];
const languageLevels = ['Basic', 'Intermediate', 'Fluent', 'Native'];

// Add this function
const getBadgeVariant = (level, isSkill = true) => {
  if (isSkill) {
    switch (level) {
      case 'Beginner':
        return 'info';
      case 'Intermediate':
        return 'primary';
      case 'Advanced':
        return 'success';
      case 'Expert':
        return 'danger';
      default:
        return 'secondary';
    }
  } else {
    switch (level) {
      case 'Basic':
        return 'info';
      case 'Intermediate':
        return 'primary';
      case 'Fluent':
        return 'success';
      case 'Native':
        return 'danger';
      default:
        return 'secondary';
    }
  }
};

export default function SkillsForm({ data, onUpdate }) {
  const [newSkill, setNewSkill] = useState({ name: '', level: 'Intermediate' });
  const [newLanguage, setNewLanguage] = useState({ name: '', level: 'Intermediate' });

  const addSkill = () => {
    if (newSkill.name) {
      const updatedSkills = [...(data.skills || []), newSkill];
      onUpdate('skills', updatedSkills);
      setNewSkill({ name: '', level: 'Intermediate' });
    }
  };

  const removeSkill = (index) => {
    const updatedSkills = data.skills.filter((_, i) => i !== index);
    onUpdate('skills', updatedSkills);
  };

  const addLanguage = () => {
    if (newLanguage.name) {
      const updatedLanguages = [...(data.languages || []), newLanguage];
      onUpdate('languages', updatedLanguages);
      setNewLanguage({ name: '', level: 'Intermediate' });
    }
  };

  const removeLanguage = (index) => {
    const updatedLanguages = data.languages.filter((_, i) => i !== index);
    onUpdate('languages', updatedLanguages);
  };

  return (
    <div>
      <h5 className="mb-3">Technical Skills</h5>
      <div className="mb-3">
        {data.skills?.map((skill, index) => (
          <Badge
            key={index}
            bg={getBadgeVariant(skill.level)}
            className="me-2 mb-2"
            style={{ cursor: 'pointer' }}
            onClick={() => removeSkill(index)}
          >
            {skill.name} - {skill.level} ×
          </Badge>
        ))}
      </div>

      <Row className="mb-4">
        <Col md={5}>
          <Form.Group>
            <Form.Label>Skill Name</Form.Label>
            <Form.Control
              type="text"
              value={newSkill.name}
              onChange={e => setNewSkill({ ...newSkill, name: e.target.value })}
              placeholder="e.g., JavaScript, React"
            />
          </Form.Group>
        </Col>
        <Col md={5}>
          <Form.Group>
            <Form.Label>Level</Form.Label>
            <Form.Select
              value={newSkill.level}
              onChange={e => setNewSkill({ ...newSkill, level: e.target.value })}
            >
              {skillLevels.map(level => (
                <option key={level} value={level}>{level}</option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={2}>
          <Button
            variant="success"
            className="mt-4 w-100"
            onClick={addSkill}
          >
            Add
          </Button>
        </Col>
      </Row>

      <h5 className="mb-3 mt-4">Languages</h5>
      <div className="mb-3">
        {data.languages?.map((language, index) => (
          <Badge
            key={index}
            bg={getBadgeVariant(language.level, false)}
            className="me-2 mb-2"
            style={{ cursor: 'pointer' }}
            onClick={() => removeLanguage(index)}
          >
            {language.name} - {language.level} ×
          </Badge>
        ))}
      </div>

      <Row className="mb-4">
        <Col md={5}>
          <Form.Group>
            <Form.Label>Language</Form.Label>
            <Form.Control
              type="text"
              value={newLanguage.name}
              onChange={e => setNewLanguage({ ...newLanguage, name: e.target.value })}
              placeholder="e.g., English, Spanish"
            />
          </Form.Group>
        </Col>
        <Col md={5}>
          <Form.Group>
            <Form.Label>Level</Form.Label>
            <Form.Select
              value={newLanguage.level}
              onChange={e => setNewLanguage({ ...newLanguage, level: e.target.value })}
            >
              {languageLevels.map(level => (
                <option key={level} value={level}>{level}</option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={2}>
          <Button
            variant="success"
            className="mt-4 w-100"
            onClick={addLanguage}
          >
            Add
          </Button>
        </Col>
      </Row>
    </div>
  );
}