import React, { useState } from 'react';
import { Container, Button } from 'react-bootstrap';
import CVModalManager from '../../components/cv/cvModalManager';

export default function CreateCVPage() {
  const [showModal, setShowModal] = useState(false);

  return (
    <Container className="py-5">
      <div className="text-center">
        <h1 className="mb-4">Create Your Professional CV</h1>
        <p className="lead mb-4">
          Build a comprehensive CV in minutes with our step-by-step guide.
          We'll help you create a professional CV that stands out.
        </p>
        <Button 
          size="lg" 
          variant="primary" 
          onClick={() => setShowModal(true)}
        >
          Start Building Your CV
        </Button>
      </div>

      {/* Only render the modal manager when showModal is true */}
      {showModal && (
        <CVModalManager
          show={showModal}
          onHide={() => setShowModal(false)}
        />
      )}
    </Container>
  );
}