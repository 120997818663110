import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, InputGroup } from 'react-bootstrap';
import Select from 'react-select';
import { jobCategories, countries, experienceLevels } from '../../utils/constants';

const JobPostingForm = ({ initialData, onSubmit, onCancel }) => {
  const [formData, setFormData] = useState({
    title: initialData?.title || '',
    description: initialData?.description || '',
    category: initialData?.category || '',
    country: initialData?.country || '',
    city: initialData?.city || '',
    type: initialData?.type || 'full-time',
    skills: initialData?.skills || [],
    experienceLevel: initialData?.experienceLevel || 'mid-level',
    minSalary: initialData?.minSalary || '',
    maxSalary: initialData?.maxSalary || '',
    status: initialData?.status || 'draft'
  });

  const [errors, setErrors] = useState({});
  const [availableCities, setAvailableCities] = useState([]);

  // Format options for react-select
  const countryOptions = countries.map(country => ({
    value: country.value,
    label: country.label
  }));

  const cityOptions = availableCities.map(city => ({
    value: city,
    label: city
  }));

  const statusOptions = [
    { value: 'draft', label: 'Draft' },
    { value: 'active', label: 'Active' },
    { value: 'closed', label: 'Closed' }
  ];
  

  useEffect(() => {
    if (formData.country) {
      const selectedCountry = countries.find(c => c.value === formData.country);
      if (selectedCountry) {
        setAvailableCities(selectedCountry.cities || []);
        if (!selectedCountry.cities.includes(formData.city)) {
          setFormData(prev => ({ ...prev, city: '' }));
        }
      }
    } else {
      setAvailableCities([]);
      setFormData(prev => ({ ...prev, city: '' }));
    }
  }, [formData.country]);

  // Salary validation
  useEffect(() => {
    const newErrors = {};
    
    if (formData.minSalary && formData.maxSalary) {
      const min = parseInt(formData.minSalary);
      const max = parseInt(formData.maxSalary);
      
      if (min >= max) {
        newErrors.salary = 'Maximum salary must be greater than minimum salary';
      }
      if (min < 0) {
        newErrors.minSalary = 'Salary cannot be negative';
      }
      if (max < 0) {
        newErrors.maxSalary = 'Salary cannot be negative';
      }
    }

    setErrors(newErrors);
  }, [formData.minSalary, formData.maxSalary]);

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Validate before submit
    if (Object.keys(errors).length === 0) {
      onSubmit(formData);
    }
  };

  const handleSalaryChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleCountryChange = (selected) => {
    setFormData(prev => ({
      ...prev,
      country: selected?.value || '',
      city: '' // Reset city when country changes
    }));
  };

  const handleCityChange = (selected) => {
    setFormData(prev => ({
      ...prev,
      city: selected?.value || '',
      location: selected?.value === 'Other' ? '' : selected?.value
    }));
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col md={8}>
        <Form.Group className="mb-3">
            <Form.Label>Job Title</Form.Label>
            <Form.Control
              type="text"
              value={formData.title}
              onChange={(e) => setFormData({...formData, title: e.target.value})}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Job Category</Form.Label>
            <Form.Select
              value={formData.category}
              onChange={(e) => setFormData({...formData, category: e.target.value})}
              required
            >
              <option value="">Select a category</option>
              {jobCategories.map(category => (
                <option key={category.value} value={category.value}>
                  {category.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Experience Level</Form.Label>
            <Form.Select
              value={formData.experienceLevel}
              onChange={(e) => setFormData({...formData, experienceLevel: e.target.value})}
              required
            >
              <option value="">Select experience level</option>
              {experienceLevels.map(level => (
                <option key={level.value} value={level.value}>
                  {level.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Job Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              value={formData.description}
              onChange={(e) => setFormData({...formData, description: e.target.value})}
              required
            />
          </Form.Group>
        </Col>

        <Col md={4}>
          <Form.Group className="mb-3">
            <Form.Label>Country</Form.Label>
            <Select
              value={countryOptions.find(option => option.value === formData.country)}
              onChange={handleCountryChange}
              options={countryOptions}
              isClearable
              placeholder="Select a country"
              className="react-select-container"
              classNamePrefix="react-select"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>City</Form.Label>
            <Select
              value={cityOptions.find(option => option.value === formData.city)}
              onChange={handleCityChange}
              options={cityOptions}
              isDisabled={!formData.country}
              isClearable
              placeholder="Select a city"
              className="react-select-container"
              classNamePrefix="react-select"
            />
          </Form.Group>

          {formData.city === 'Other' && (
            <Form.Group className="mb-3">
              <Form.Label>Enter City Name</Form.Label>
              <Form.Control
                type="text"
                value={formData.location}
                onChange={(e) => setFormData({...formData, location: e.target.value})}
                required
                placeholder="Enter city name"
              />
            </Form.Group>
          )}

          <Form.Group className="mb-3">
            <Form.Label>
              Salary Range (EUR)
              {errors.salary && <span className="text-danger ms-2 small">{errors.salary}</span>}
            </Form.Label>
            <Row>
              <Col>
                <InputGroup>
                  <Form.Control
                    type="number"
                    value={formData.minSalary}
                    onChange={(e) => handleSalaryChange('minSalary', e.target.value)}
                    placeholder="Min"
                    isInvalid={!!errors.minSalary}
                  />
                </InputGroup>
                {errors.minSalary && (
                  <Form.Text className="text-danger">
                    {errors.minSalary}
                  </Form.Text>
                )}
              </Col>
              <Col>
                <InputGroup>
                  <Form.Control
                    type="number"
                    value={formData.maxSalary}
                    onChange={(e) => handleSalaryChange('maxSalary', e.target.value)}
                    placeholder="Max"
                    isInvalid={!!errors.maxSalary}
                  />
                </InputGroup>
                {errors.maxSalary && (
                  <Form.Text className="text-danger">
                    {errors.maxSalary}
                  </Form.Text>
                )}
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className="mb-3">
  <Form.Label>Status</Form.Label>
  <Form.Select
    value={formData.status}
    onChange={(e) => setFormData({...formData, status: e.target.value})}
    required
  >
    {statusOptions.map(status => (
      <option key={status.value} value={status.value}>
        {status.label}
      </option>
    ))}
  </Form.Select>
</Form.Group>
        </Col>
      </Row>

      <div className="d-flex justify-content-end gap-2 mt-3">
        <Button variant="outline-secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="submit" variant="primary">
          {initialData ? 'Update Job' : 'Create Job'}
        </Button>
      </div>
    </Form>
  );
};

export default JobPostingForm;