// Pages/CompanyDashboard/CompanyProfile.js
import React, { useState, useContext, useEffect  } from 'react';
import { Container, Form, Button, Card, Spinner  } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import { Store } from '../../context/context';
import api from '../../config/axios';

export default function CompanyProfile() {
    const { state, dispatch: ctxDispatch } = useContext(Store);
    const { companyInfo } = state;
    const [loading, setLoading] = useState(true);
  
    const [formData, setFormData] = useState({
      companyName: '',
      industry: '',
      description: '',
      culture: '',
      requirements: '',
      website: '',
      location: '',
      size: ''
    });

    // Fetch initial profile data
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const { data } = await api.get('/companies/profile');
        setFormData({
          companyName: data.companyName || '',
          industry: data.industry || '',
          description: data.description || '',
          culture: data.culture || '',
          requirements: data.requirements || '',
          website: data.website || '',
          location: data.location || '',
          size: data.size || ''
        });
      } catch (error) {
        toast.error(error.response?.data?.message || 'Error loading profile');
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await api.put('/companies/profile', formData);
      ctxDispatch({ 
        type: 'COMPANY_PROFILE_UPDATE_SUCCESS', 
        payload: data 
      });
      toast.success('Profile updated successfully');
    } catch (error) {
      toast.error(error.response?.data?.message || 'Error updating profile');
    }
  };

  if (loading) {
    return (
      <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '80vh' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }



  return (
    <Container className="py-4">
      <Helmet>
        <title>Company Profile</title>
      </Helmet>

      <Card>
        <Card.Body>
          <h2 className="mb-4">Company Profile</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                type="text"
                value={formData.companyName}
                onChange={(e) => setFormData({...formData, companyName: e.target.value})}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Industry</Form.Label>
              <Form.Control
                type="text"
                value={formData.industry}
                onChange={(e) => setFormData({...formData, industry: e.target.value})}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Company Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={formData.description}
                onChange={(e) => setFormData({...formData, description: e.target.value})}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Company Culture</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={formData.culture}
                onChange={(e) => setFormData({...formData, culture: e.target.value})}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>General Requirements for candidates</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={formData.requirements}
                onChange={(e) => setFormData({...formData, requirements: e.target.value})}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Website</Form.Label>
              <Form.Control
                type="url"
                value={formData.website}
                onChange={(e) => setFormData({...formData, website: e.target.value})}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Location</Form.Label>
              <Form.Control
                type="text"
                value={formData.location}
                onChange={(e) => setFormData({...formData, location: e.target.value})}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Company Size</Form.Label>
              <Form.Select
                value={formData.size}
                onChange={(e) => setFormData({...formData, size: e.target.value})}
              >
                <option value="">Select Size</option>
                <option value="1-10">1-10 employees</option>
                <option value="11-50">11-50 employees</option>
                <option value="51-200">51-200 employees</option>
                <option value="201-500">201-500 employees</option>
                <option value="501+">501+ employees</option>
              </Form.Select>
            </Form.Group>

            <Button type="submit" variant="primary">
              Update Profile
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
}