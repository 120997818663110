import axios from 'axios';

const api = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

const PUBLIC_ROUTES = [
  '/users/login',
  '/users/register',
  '/companies/login',
  '/companies/register',
  '/jobs/public'
];

const COMPANY_ROUTES = [
  '/companies',
  '/jobs',
  '/ai/match/candidates',
  '/company-profile',
  '/users/search',
  '/applications/company',
  '/applications/_id/',
  '/applications/evaluate' // Add this new route // This will match application detail routes using MongoDB _id
];

const USER_ROUTES = [
  '/users',
  '/ai/match/jobs',
  '/applications/user',    
  '/applications/submit',
  '/evaluations/request',
  '/evaluations/history',
  '/evaluations'
];

// Update the request interceptor
api.interceptors.request.use((config) => {
  const path = config.url?.split('?')[0].replace(config.baseURL || '', '');
  console.log('Request details:', {
    fullPath: config.url,
    processedPath: path,
    method: config.method,
    headers: config.headers
  });

  // Check if it's a public route
  if (PUBLIC_ROUTES.some(route => path.startsWith(route))) {
    console.log('Public route accessed:', path);
    return config;
  }

  // Check if it's a company route
  if (COMPANY_ROUTES.some(route => {
    // Special handling for MongoDB _id routes
    if (route.includes('_id')) {
      return path.startsWith('/applications/') && 
             path.split('/')[2]?.length === 24; // MongoDB ObjectId length check
    }
    return path.startsWith(route);
  })) {
    const companyInfo = localStorage.getItem('companyInfo');
    if (companyInfo) {
      try {
        const info = JSON.parse(companyInfo);
        config.headers['Authorization'] = `Bearer ${info.token}`;
        config.headers['Company-Email'] = info.email;
        console.log('Added company authorization for:', path, {
          token: `${info.token.substring(0, 10)}...`,
          email: info.email
        });
      } catch (error) {
        console.error('Error parsing company info:', error);
      }
    } else {
      console.log('Company route accessed without company auth:', path);
    }
    return config;
  }

  // Check if it's a user route
  if (USER_ROUTES.some(route => path.startsWith(route))) {
    const userInfo = localStorage.getItem('userInfo');
    if (userInfo) {
      try {
        const { token } = JSON.parse(userInfo);
        config.headers['Authorization'] = `Bearer ${token}`;
        console.log('Added user authorization for:', path);
      } catch (error) {
        console.error('Error parsing user info:', error);
      }
    } else {
      console.log('User route accessed without user auth:', path);
    }
    return config;
  }

  // If route doesn't match any patterns, determine auth based on available tokens
  const companyInfo = localStorage.getItem('companyInfo');
  const userInfo = localStorage.getItem('userInfo');

  if (companyInfo) {
    try {
      const info = JSON.parse(companyInfo);
      config.headers['Authorization'] = `Bearer ${info.token}`;
      config.headers['Company-Email'] = info.email;
      console.log('Added default company authorization for:', path);
    } catch (error) {
      console.error('Error parsing company info:', error);
    }
  } else if (userInfo) {
    try {
      const { token } = JSON.parse(userInfo);
      config.headers['Authorization'] = `Bearer ${token}`;
      console.log('Added default user authorization for:', path);
    } catch (error) {
      console.error('Error parsing user info:', error);
    }
  }

  return config;
});

// Update the response interceptor to include more detailed logging
api.interceptors.response.use(
  (response) => {
    console.log('Response received:', {
      url: response.config.url,
      status: response.status,
      dataLength: Array.isArray(response.data) ? response.data.length : 'N/A'
    });
    return response;
  },
  (error) => {
    const errorDetails = {
      status: error.response?.status,
      message: error.response?.data?.message,
      url: error.config?.url,
      method: error.config?.method,
      requestHeaders: error.config?.headers,
      responseHeaders: error.response?.headers
    };

    console.error('API Error:', errorDetails);
    return Promise.reject(error);
  }
);

export default api;
